var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200" },
      model: {
        value: _vm.updateCreativeSetDialog,
        callback: function($$v) {
          _vm.updateCreativeSetDialog = $$v
        },
        expression: "updateCreativeSetDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-5"
            },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$t("editCreativeSet.editCreative")))
              ]),
              _c(
                "v-icon",
                {
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "logo d-flex align-center mb-5" },
                        [
                          _c("v-img", {
                            staticClass: "mr-3",
                            attrs: {
                              "max-height": "60",
                              "max-width": "60",
                              src: require("../../assets/images/creator-logo.jpg")
                            }
                          }),
                          _c("div", { staticClass: "logo-content" }, [
                            _c("p", { staticClass: "app-name mb-0" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("editCreativeSet.easywebinar"))
                                )
                              ])
                            ]),
                            _c("span", { staticClass: "developer-name" }, [
                              _vm._v(_vm._s(_vm.$t("editCreativeSet.softobiz")))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("editCreativeSet.setup")))
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("editCreativeSet.editCreativePara1")
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-label", { staticClass: "mb-4" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("editCreativeSet.creativeSetName")
                                  ) + " "
                                ),
                                _c("sup", { staticClass: "red--text" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.csName,
                                  callback: function($$v) {
                                    _vm.csName = $$v
                                  },
                                  expression: "csName"
                                }
                              }),
                              _c("v-label", { staticClass: "mb-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("editCreativeSet.localLang")) +
                                    " "
                                ),
                                _c("sup", { staticClass: "red--text" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  disabled: "",
                                  outlined: "",
                                  dense: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.displayLanguage,
                                  callback: function($$v) {
                                    _vm.displayLanguage = $$v
                                  },
                                  expression: "displayLanguage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-3" }),
                      _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "8" } }, [
                            _c(
                              "h4",
                              [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("editCreativeSet.assetSelection")
                                    ) + " "
                                  )
                                ]),
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-help")
                                ])
                              ],
                              1
                            ),
                            _c("small", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "editCreativeSet.assetSelectionPararaph1"
                                  )
                                ) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "editCreativeSet.assetSelectionPararaph2"
                                  )
                                ) + " "
                              ),
                              _c("strong", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "editCreativeSet.assetSelectionPararaph3"
                                    )
                                  )
                                )
                              ]),
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "editCreativeSet.assetSelectionPararaph4"
                                  )
                                ) + " "
                              ),
                              _c("strong", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "editCreativeSet.assetSelectionPararaph5"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-width": 200,
                                    "offset-x": "",
                                    "max-width": "500"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    depressed: "",
                                                    small: "",
                                                    text: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "editCreativeSet.viewDevice"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.menuDisplays,
                                    callback: function($$v) {
                                      _vm.menuDisplays = $$v
                                    },
                                    expression: "menuDisplays"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editCreativeSet.title1"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("v-simple-table", {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.phones,
                                                                function(item) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key:
                                                                        item.size
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.size
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.description
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editCreativeSet.ipad"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("v-simple-table", {
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.ipads,
                                                                function(item) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key:
                                                                        item.size
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.size
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.description
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.allImgs.tabs != undefined
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-expansion-panels",
                                { attrs: { accordion: "" } },
                                _vm._l(_vm.allImgs.tabs, function(i) {
                                  return _c(
                                    "v-expansion-panel",
                                    { key: i },
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between align-center"
                                        },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(i) +
                                                " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "editCreativeSet.display"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-row",
                                            _vm._l(
                                              _vm.allImgs[i].length,
                                              function(n) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: n,
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "3"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-title",
                                                      {
                                                        staticClass:
                                                          "pa-0 mb-3 caption justify-center"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "editCreativeSet.default"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-img", {
                                                      attrs: {
                                                        "max-height": "150",
                                                        "max-width": "204",
                                                        src:
                                                          _vm.allImgs[i][n - 1]
                                                            .assetURL,
                                                        "lazy-src":
                                                          _vm.allImgs[i][n - 1]
                                                            .assetURL,
                                                        "aspect-ratio": "0.5"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "placeholder",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "fill-height ma-0",
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                      justify:
                                                                        "center"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-progress-circular",
                                                                      {
                                                                        attrs: {
                                                                          indeterminate:
                                                                            "",
                                                                          color:
                                                                            "grey lighten-5"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.updatingCs,
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("editCreativeSet.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.updatingCs,
                    loading: _vm.updatingCs,
                    width: "127",
                    color: "success",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateCreativeSet()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("editCreativeSet.save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }