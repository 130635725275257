































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DELETE_CAMPAIGN } from 'src/utils/apollo-mutations';
import Vue from 'vue';
export default Vue.extend({
  props: {
    campaignDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    campaignDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteCampaign() {
      this.deleting = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: DELETE_CAMPAIGN,
          variables: {
            orgId: this.campaignDetailsForDeletion.orgId,
            campaignId: this.campaignDetailsForDeletion.campaignId,
          },
        });

        if (!result.data.deleteCampaign.successMessage) {
          this.$notify.error('Error while deleting campaign');
        } else {
          this.$notify.success('Campaign Deleted');
          this.$emit('onDeleteCampaign');
        }
        this.deleting = false;
        this.dialog = false;
      } catch (error) {
        this.deleting = false;
        console.log(error);
      }
    },
  },
});
