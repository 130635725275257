var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-0"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addKeywords.h6AddKeywords")))
              ]),
              _c(
                "v-icon",
                {
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-5" },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-stepper",
                      {
                        model: {
                          value: _vm.step,
                          callback: function($$v) {
                            _vm.step = $$v
                          },
                          expression: "step"
                        }
                      },
                      [
                        _c(
                          "v-stepper-header",
                          { staticClass: "justify-center" },
                          [
                            _c(
                              "v-stepper-step",
                              { staticClass: "pa-2", attrs: { step: "1" } },
                              [
                                _c("span", { staticClass: "success--text" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("addKeywords.selectTarget"))
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-items",
                          [
                            _c(
                              "v-stepper-content",
                              { staticClass: "pa-0", attrs: { step: "1" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "my-2" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "logo d-flex align-center mb-5"
                                              },
                                              [
                                                _c("v-img", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    color: "secondary",
                                                    "lazy-src":
                                                      _vm.selectedAppIcon,
                                                    "max-width": "60",
                                                    "max-height": "60",
                                                    "min-width": "20",
                                                    "min-height": "20",
                                                    src: _vm.selectedAppIcon
                                                  }
                                                }),
                                                _vm.selectedAppName
                                                  ? _c("v-divider", {
                                                      staticClass: "ml-13 mr-8",
                                                      attrs: { vertical: "" }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "logo-content"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "app-name mb-0"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.selectedAppName
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "developer-name"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedAppDev
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "12", sm: "6" }
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "font-weight-medium subtitle-2 secondary--text"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addKeywords.h4Camp"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.totalCampaigns) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeywords.selected"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      depressed: "",
                                                      text: "",
                                                      disabled: _vm.saving,
                                                      color: "info"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.change()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeywords.change"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "font-weight-medium subtitle-2 secondary--text"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addKeywords.h4Adgroups"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.totalAdgroups) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeywords.selected"
                                                        )
                                                      )
                                                  )
                                                ]),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      depressed: "",
                                                      text: "",
                                                      disabled: _vm.saving,
                                                      color: "info"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.change()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeywords.change"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "max-bid d-flex" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "d-flex mt-4 mr-2 font-weight-medium subtitle-2 secondary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeywords.maxCPTBid"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    items: _vm.maxCptBidOptions,
                                                    label: "Select CPT Bid",
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value: _vm.maxCptBid,
                                                    callback: function($$v) {
                                                      _vm.maxCptBid = $$v
                                                    },
                                                    expression: "maxCptBid"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  height: "100%"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "d-flex pa-2"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "addKeywords.keywordSuggestionsFor"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.selectedAppName
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addKeywords.on"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.selectedCountry
                                                              .name
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "pa-2 rounded-0 border-x-0",
                                                    attrs: {
                                                      outlined: "",
                                                      height: "75"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "py-0 pt-3",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "rounded-lg",
                                                              attrs: {
                                                                items:
                                                                  _vm.searchedApps,
                                                                loading:
                                                                  _vm.loadingApps,
                                                                label:
                                                                  "Select App",
                                                                "item-text":
                                                                  "appName",
                                                                "item-value":
                                                                  "adamId",
                                                                required: "",
                                                                outlined: "",
                                                                dense: "",
                                                                "prepend-inner-icon":
                                                                  "mdi-cellphone",
                                                                "return-object":
                                                                  ""
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.changeApp
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.searchApp,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.searchApp = $$v
                                                                },
                                                                expression:
                                                                  "searchApp"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "py-0 pt-3",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "6"
                                                            }
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "no-field-details",
                                                              attrs: {
                                                                items:
                                                                  _vm.countries,
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "Country is required"
                                                                    )
                                                                  }
                                                                ],
                                                                label:
                                                                  "Country",
                                                                required: "",
                                                                outlined: "",
                                                                dense: "",
                                                                "item-text":
                                                                  "name",
                                                                "item-value":
                                                                  "code",
                                                                "return-object":
                                                                  "",
                                                                height: "55"
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.countryChange
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectedCountry,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.selectedCountry = $$v
                                                                },
                                                                expression:
                                                                  "selectedCountry"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "keyword-table"
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "text-center mt-2"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addKeywords.recommendedKeywords"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card",
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                "append-icon":
                                                                  "mdi-magnify",
                                                                label:
                                                                  "Search here",
                                                                outlined: "",
                                                                dense: "",
                                                                "single-line":
                                                                  "",
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.searchedKeyword,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.searchedKeyword = $$v
                                                                },
                                                                expression:
                                                                  "searchedKeyword"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.recomendHeaders,
                                                            items:
                                                              _vm.recomendItems,
                                                            search:
                                                              _vm.searchedKeyword,
                                                            loading:
                                                              _vm.loadingRecomends,
                                                            "loading-text":
                                                              "Loading... Please wait"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "item.addKeywordPlus",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var item =
                                                                    ref.item
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mx-2 pa-0",
                                                                        attrs: {
                                                                          width:
                                                                            "22",
                                                                          height:
                                                                            "18",
                                                                          "min-width":
                                                                            "20",
                                                                          text:
                                                                            "",
                                                                          depressed:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.addKeywordPlusClicked(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "success--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-plus-circle-outline"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "form",
                                                attrs: {
                                                  role: "form",
                                                  "lazy-validation": ""
                                                },
                                                on: {
                                                  submit: function($event) {
                                                    $event.preventDefault()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.valid,
                                                  callback: function($$v) {
                                                    _vm.valid = $$v
                                                  },
                                                  expression: "valid"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      outlined: "",
                                                      height: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex pa-2"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addKeywords.addedKeywords"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pagination pa-2"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "pagination-total secondary--text float-right position-relative"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "addKeywords.total"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.keywords
                                                                    .length
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-2 rounded-0 border-x-0 gg",
                                                        staticStyle: {
                                                          border: "none"
                                                        },
                                                        attrs: {
                                                          outlined: "",
                                                          height: "58"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "addkeword"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "rounded-0 rounded-l-lg mb-0",
                                                                    attrs: {
                                                                      required:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      rules:
                                                                        _vm.keyRules
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.keywordInput,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.keywordInput = $$v
                                                                      },
                                                                      expression:
                                                                        "keywordInput"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "rounded-0 rounded-r-lg border-l-0 grey--text",
                                                                    attrs: {
                                                                      width:
                                                                        "36",
                                                                      height:
                                                                        "40",
                                                                      depressed:
                                                                        "",
                                                                      outlined:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.addKeyword()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "secondary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "addKeywords.add"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "keword-lists pa-2",
                                                        staticStyle: {
                                                          overflow: "scroll",
                                                          height: "100px"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.keywords,
                                                        function(keyword) {
                                                          return _c(
                                                            "v-row",
                                                            {
                                                              key:
                                                                keyword["text"]
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 pt-3",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        width:
                                                                          "32",
                                                                        depressed:
                                                                          "",
                                                                        "x-small":
                                                                          "",
                                                                        fab: "",
                                                                        text:
                                                                          "",
                                                                        color:
                                                                          "error"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteKeyword(
                                                                            keyword[
                                                                              "text"
                                                                            ]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-delete-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        keyword[
                                                                          "text"
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 pt-3",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "no-field-details",
                                                                      attrs: {
                                                                        items:
                                                                          _vm.matchTypes,
                                                                        label:
                                                                          "Match Type",
                                                                        required:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          keyword[
                                                                            "matchType"
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            keyword,
                                                                            "matchType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "keyword['matchType']"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 pt-3",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            type:
                                                                              "text",
                                                                            required:
                                                                              "",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            "prepend-inner-icon":
                                                                              "mdi-currency-usd",
                                                                            rules:
                                                                              _vm.amountRules
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              keyword[
                                                                                "bidAmount"
                                                                              ][
                                                                                "amount"
                                                                              ],
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                keyword[
                                                                                  "bidAmount"
                                                                                ],
                                                                                "amount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "keyword['bidAmount']['amount']"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.saving,
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeywords.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.saving,
                    loading: _vm.saving,
                    width: "127",
                    color: "success",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeywords.apply")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "300" },
              model: {
                value: _vm.msgModel,
                callback: function($$v) {
                  _vm.msgModel = $$v
                },
                expression: "msgModel"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline grey lighten-2" },
                    [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                  ),
                  _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.msgModel = false
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("addKeywords.ok")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }