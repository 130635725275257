var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.bidAmountDialog,
        callback: function($$v) {
          _vm.bidAmountDialog = $$v
        },
        expression: "bidAmountDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-5", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-space-between pa-0 mb-0" },
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("editBidAmount.bidAmount")))]),
              _c(
                "v-icon",
                {
                  attrs: { disabled: _vm.saving },
                  on: {
                    click: function($event) {
                      return _vm.bidAmountDialogClose()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              [
                _c(
                  "v-card",
                  {
                    staticClass: "pa-2 rounded-0 border-x-0",
                    attrs: { outlined: "", height: "58" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("v-text-field", {
                          staticClass: "rounded-md no-field-details mb-0",
                          attrs: {
                            required: "",
                            outlined: "",
                            dense: "",
                            "prepend-inner-icon": "mdi-currency-usd",
                            rules: _vm.amountRules
                          },
                          model: {
                            value: _vm.keywordDetails.bidAmount.amount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.keywordDetails.bidAmount,
                                "amount",
                                $$v
                              )
                            },
                            expression: "keywordDetails.bidAmount.amount"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-md success--text mx-3",
                            attrs: {
                              width: "36",
                              height: "40",
                              depressed: "",
                              outlined: "",
                              disabled: _vm.saving,
                              loading: _vm.saving
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeKeywordBidAmount()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-check")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-md error--text",
                            attrs: {
                              width: "36",
                              height: "40",
                              depressed: "",
                              outlined: "",
                              disabled: _vm.saving
                            },
                            on: {
                              click: function($event) {
                                return _vm.bidAmountDialogClose()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }