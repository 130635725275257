var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "290" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" " + _vm._s(_vm.$t("deleteCampaign.deleteCamp")) + " ")
          ]),
          _c("v-card-text", [_vm._v(_vm._s(_vm.$t("deleteCampaign.para")))]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.deleting,
                    color: "green darken-1",
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("deleteCampaign.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.deleting,
                    loading: _vm.deleting,
                    color: "red darken-1",
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteCampaign()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("deleteCampaign.delete")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }