var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { attrs: { flat: "", width: "100%", height: "auto" } }, [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        _c("v-select", {
          attrs: {
            "menu-props": "closeOnContentClick",
            items: _vm.headers,
            "item-text": "text",
            attach: "",
            dense: "",
            outlined: "",
            label: "Select Datasets",
            "return-object": "",
            multiple: "",
            "small-chips": "",
            "deletable-chips": ""
          },
          model: {
            value: _vm.selectedHeaders,
            callback: function($$v) {
              _vm.selectedHeaders = $$v
            },
            expression: "selectedHeaders"
          }
        }),
        _c("v-data-table", {
          directives: [
            {
              name: "sortable-table",
              rawName: "v-sortable-table",
              value: { onEnd: _vm.sortTheHeadersAndUpdateTheKey },
              expression: "{ onEnd: sortTheHeadersAndUpdateTheKey }"
            }
          ],
          key: _vm.anIncreasingNumber,
          staticClass: "elevation-0",
          attrs: {
            loading: _vm.loading,
            "items-per-page": 5,
            headers: _vm.finalHeaders,
            items: _vm.items
          },
          scopedSlots: _vm._u(
            [
              _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              })
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }