









































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { controllers } from 'chart.js';
import * as _ from 'lodash';
import Vue from 'vue';
import { UPDATE_CAMPAIGN } from '../../utils/apollo-mutations';
import { COUNTRIES } from '../../utils/constants';

export default Vue.extend({
  name: 'EditCampaign',
  data() {
    return {
      saving: false,
      show: false,
      countries: COUNTRIES,
      valid: false,
      campaignDetails: {
        name: null,
        countriesOrRegions: [],
        budgetAmount: {
          amount: null,
        },
      },
      dailyBudgetAmount: null,
      dailyBudgetCurrency: null,
      nameRules: [(v) => !!v || 'Campaign Name is Required'],
      countryRules: [(v) => !!v || 'Country is Required'],
      budgetRules: [(v) => !!v || 'Budget Amount Required'],
    };
  },
  props: {
    campaignData: {
      type: Object,
      default() {
        return;
      },
    },
  },

  watch: {
    campaignData: function (data) {
      this.show = true;

      if (data.dailyBudgetAmount == null) {
        this.dailyBudgetAmount = null;
      } else {
        this.dailyBudgetAmount = data.dailyBudgetAmount.amount;
        this.dailyBudgetCurrency = data.dailyBudgetAmount.currency;
      }

      this.campaignDetails = _.cloneDeep({
        name: data.name,
        countriesOrRegions: data.countriesOrRegions,
        budgetAmount: data.budgetAmount,
      });
    },
  },
  methods: {
    async updateCampaign() {
      this.saving = true;
      const campaignData = {
        name: this.campaignDetails.name,
        countriesOrRegions: this.campaignDetails.countriesOrRegions,
        budgetAmount: this.campaignDetails.budgetAmount,
        ...(this.dailyBudgetAmount && {
          dailyBudgetAmount: {
            amount: this.dailyBudgetAmount,
            currency: this.dailyBudgetCurrency,
          },
        }),
      };
      const campaignRequest = {
        // should be true if countriesOrRegions is changed
        clearGeoTargetingOnCountryOrRegionChange: !_.isEqual(
          this.campaignDetails.countriesOrRegions.sort(),
          this.campaignData.countriesOrRegions.sort()
        ),
        campaign: campaignData,
      };

      const campaignId = this.campaignData.campaignId;
      const orgId = this.campaignData.orgId;

      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            orgId,
            campaignId,
            campaignRequest,
          },
        });

        if (result.data.updateCampaign.searchAdsErrors) {
          this.$notify.error(result.data.updateCampaign.searchAdsErrors);
        } else {
          this.$notify.success('Campaign updated.');
          this.close();
        }
      } catch {}

      this.saving = false;
    },

    close() {
      this.show = false;
      this.campaignDetails = {
        name: null,
        countriesOrRegions: [],
        budgetAmount: {
          amount: null,
        },
        dailyBudgetAmount: {
          amount: null,
        },
      };
    },
  },
});
