var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "total-summary mt-5" }, [
    _c(
      "div",
      { staticClass: "total-summary-header text-center mb-5" },
      [
        _c(
          "v-subheader",
          { staticClass: "justify-center font-weight-bold bluegrey--text" },
          [_vm._v(_vm._s(_vm.$t("labels.totalSummary")))]
        ),
        _c(
          "p",
          { staticClass: "headline font-weight-bold text-h5 primary--text" },
          [
            _vm._v(
              " $" +
                _vm._s(_vm._f("readableNumber")(_vm.values.localSpend.amount)) +
                " "
            ),
            _c("span", { staticClass: "ml-2 grey--text-2 subtitle-2" }, [
              _vm._v(_vm._s(_vm.$t("labels.spend")))
            ])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex total-summary-content flex-wrap" },
      _vm._l(_vm.summaryItems, function(item, i) {
        return _c(
          "v-sheet",
          {
            key: i,
            staticClass:
              "justify-center d-flex flex-column font-weight-medium text-h5",
            attrs: {
              color: "white",
              elevation: "0",
              height: "152",
              width: "100%",
              "max-width": "33.333%"
            }
          },
          [
            _c("div", { staticClass: "summary-inner-content" }, [
              _c(
                "p",
                {
                  staticClass:
                    "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info"
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("readableNumber")(_vm.getValue(item.key)))
                    )
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "pa-0 mt-2",
                                        attrs: {
                                          text: "",
                                          width: "20",
                                          height: "16",
                                          "min-width": "16"
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "secondary--text",
                                        attrs: { small: "" }
                                      },
                                      [_vm._v("mdi-help-circle-outline")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                  )
                ],
                1
              ),
              _c("span", { staticClass: "grey--text subtitle-2" }, [
                _vm._v(" " + _vm._s(item.title) + " ")
              ])
            ])
          ]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }