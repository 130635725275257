








































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import Sortable from 'sortablejs';

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        const currentClassState = mutation.target.classList.contains(
          classToWatch
        );
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

export default Vue.extend({
  el: 'app',
  name: 'DataTable',
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anIncreasingNumber: 1,
      finalHeaders: null
    };
  },

  computed: {
    selectedHeaders: {
      get() {
        this.finalHeaders = this.headers.slice(0, 9);
        return this.headers.slice(0, 9);
      },
      set(val) {
        this.finalHeaders = val;
      }
    },
  },

  methods: {
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.finalHeaders;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
  },
  directives: {
    'sortable-table': {
      inserted: (el, binding) => {
        el.querySelectorAll('th').forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
        Sortable.create(
          el.querySelector('tr'),
          binding.value ? { ...binding.value, handle: '.sortHandle' } : {}
        );
      },
    },
  },
});
