

























































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CampaignsService } from '@services/campaigns.service';
import XLSX from 'xlsx';
import * as _ from 'lodash';
import {
  APPS_BY_ORG,
  GET_CAMPAIGNS_BY_ORG,
  GET_CAMPAIGN_GROUPS,
} from 'src/utils/apollo-queries';
import { COUNTRIES } from 'src/utils/constants';
import Vue from 'vue';
import { CustomerTypes, Genders } from '../../utils/enums';
import { ADD_ADGROUP } from 'src/utils/apollo-mutations';
import AddKeys from '../keywords/AddKeys.vue';
import AddnKeys from '../keywords/AddnKeys.vue';
import { updateCLF } from '../../utils/util'

export default Vue.extend({
  name: 'AddAdGroup',
  data() {
    return {
      saving: false,
      adGroup: false,
      adgroupDetails: null,

      selectedAccount: null,

      selectedCampaign: null,

      orgId: null,
      status: null,
      name: null,
      Devices: ['IPAD', 'IPHONE'],
      adamId: '',
      defaultBidAmount: '',
      cpaGoal: null,
      currency: '',
      startTime: null,
      endTime: null,
      selectDevices: null,
      targetingDimensions: null,
      automatedKeywordsOptIn: null,
      selectedCountries: [],
      countries: COUNTRIES,
      autoOpt: false,
      appsListWithCampaigns: null,
      searchLoc: null,
      selectedLocation: null,
      locations: [],
      loading: false,
      endTimeCheck: false,

      datePickerProps: {
        min: new Date().toISOString(),
      },

      //audience
      ageRange: false,
      ageRangeValue: [18, 35],

      selectedGender: Genders.ALL,
      selectedCustomerType: CustomerTypes.ALL,

      //Validation
      valid: false,
      accountRules: [(v) => !!v || 'Select Campaign Group'],
      campaignRules: [(v) => !!v || 'Select Campaign'],
      nameRules: [(v) => !!v || 'Adgroup Name is Required'],
      amountRules: [
        (v) => !!v || 'Default Max CPT Bid is required',
        (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
      ],

      //Keywords
      tKeys: [],
      nKeys: [],

      addKeywordsData: {},
      addnKeywordsData: {},
    };
  },

  computed: {
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
  },

  components: {
    AddKeys,
    AddnKeys,
  },

  props: {
    openAddAdGroup: {
      type: Object,
      required: true,
    },
  },
  watch: {
    openAddAdGroup: function (data) {
      this.adGroup = true;
    },

    searchLoc(query: string): void {
      query &&
        query.length > 2 &&
        this.searchLocations(query, this.selectedCountries[0]);
    },
    selectedAccount(value) {
      // handling currency as per the org
      if (value)
        this.currency = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).currency;
    },
    selectedCampaign(value) {
      const campaign = this.getCampaignsByOrg.find(
        (campaign) => campaign.id === value
      );
      this.adamId = campaign.adamId;
      this.selectedCountries = campaign.countriesOrRegions;
    },
  },
  methods: {
    close() {
      this.adGroup = false;
      this.selectedAccount = null;
      this.selectedCampaign = null;
      this.adgroupDetails = null;

      this.name = null;
      this.defaultCpcBid = '';
      this.cpaGoal = null;
      this.startTime = null;
      this.endTime = null;
      this.selectDevices = null;
      this.targetingDimensions = null;
      this.automatedKeywordsOptIn = null;
      this.tKeys = [];
      this.selectedCountries = [];
      this.autoOpt = false;
      this.searchLoc = null;
      this.selectedLocation = null;
      this.locations = [];
      this.loading = false;
      this.endTimeCheck = false;
      this.nKeys = [];
    },

    async adGroupObject() {
      this.saving = true;
      const allOwnedApps = this.apps.map((app) => app.adamId);
      this.adgroupDetails = {
        orgId: this.selectedAccount,
        status: 'ENABLED',
        name: this.name,
        // TODO v4
        pricingModel: 'CPC',
        defaultBidAmount: {
          currency: this.currency,
          amount: this.defaultBidAmount,
        },
        ...(this.cpaGoal && {
          cpaGoal: {
            currency: this.currency,
            amount: this.cpaGoal,
          },
        }),
        startTime: this.startTime,
        ...(this.endTime && { endTime: this.endTime }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectDevices,
          },
          country: {
            included: [],
          },
          ...(this.daypart && {
            daypart: {
              userTime: {
                included: this.daypart.split(','),
              },
            },
          }),

          // age criteria
          ...(this.ageRange && {
            age: {
              included: [
                {
                  minAge: this.ageRangeValue[0],
                  maxAge: this.ageRangeValue[1],
                },
              ],
            },
          }),

          // gender criteria
          ...(this.selectedGender && {
            gender: {
              included: [this.selectedGender],
            },
          }),
          // App downloader criteria (customer type drop-down)
          ...(this.selectedCustomerType === CustomerTypes.NEW && {
            appDownloaders: {
              excluded: [this.adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
            appDownloaders: {
              included: [this.adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
            appDownloaders: {
              included: [...allOwnedApps.map((adamId) => adamId.toString())],
            },
          }),
          ...(this.selectedLocation && {
            locality: { included: this.selectedLocation },
          }),
        },
        automatedKeywordsOptIn: this.autoOpt,
      };

      await this.addAdGroup();
    },

    async addAdGroup() {
      this.saving = true;
      try {
        const adgroupRequest = {
          orgId: this.selectedAccount,
          adgroupReq: {
            campaignId: this.selectedCampaign,
            adgroup: this.adgroupDetails,
            targetKeywords: this.tKeys,
            negativeKeywords: this.nKeys,
          },
        };
        const result = await this.$apollo.mutate({
          mutation: ADD_ADGROUP,
          variables: adgroupRequest,
        });

        if (result.data.addAdgroup.searchAdsErrors) {
          this.$notify.error(result.data.addAdgroup.searchAdsErrors);
        } else {
          this.$notify.success('AdGroup added.');
          updateCLF('createAdgroup', this)
          this.close();
        }
      } catch (error) {}

      this.saving = false;
    },

    tKeysChange: async function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match,
          bidAmount: {
            currency: elem.currency,
            amount: elem.amount.toString(),
          },
        }));
        this.tKeys = result;
      };
      reader.readAsArrayBuffer(e);
    },

    nKeysChange: function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match,
        }));
        result.forEach((element) => {
          this.nKeys.push(element);
        });
      };
      reader.readAsArrayBuffer(e);
    },

    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      this.locations = await CampaignsService.searchedLocations(
        this.selectedAccount,
        locationName,
        countrycode
      );
      this.loading = false;
    },

    addTargetKeywords() {
      this.addKeywordsData = {
        appId: this.adamId,
        currency: this.currency,
        countryCode: this.selectedCountries[0],
        now: Date.now(),
      };
    },

    addTargetKeysSaveClicked(event) {
      console.log('addKeysSaveClicked', event);
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.tKeys.push(element);
      }
    },

    addTargetKeysCancelClicked() {
      console.log('addKeysCancelClicked');
    },

    addNegativeKeywords() {
      this.addnKeywordsData = {
        now: Date.now(),
      };
    },

    addnKeysSaveClicked(event) {
      console.log('addKeysSaveClicked', event);
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.nKeys.push(element);
      }
    },

    addnKeysCancelClicked() {
      console.log('addnKeysCancelClicked');
    },
  },

  apollo: {
    apps: {
      query: APPS_BY_ORG,
      variables() {
        return {
          orgId: this.selectedAccount,
        };
      },
      skip() {
        return !this.selectedAccount;
      },
    },

    getCampaignGroups: {
      query: GET_CAMPAIGN_GROUPS,
      skip() {
        return !this.adGroup;
      },
    },

    getCampaignsByOrg: {
      query: GET_CAMPAIGNS_BY_ORG,
      skip() {
        return !this.selectedAccount;
      },
      variables() {
        return {
          orgId: this.selectedAccount,
        };
      },
    },
  },
});
