




















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'TotalSummary',
  props: {
    values: {
      required: true,
      type: Object,
      default() {
        return {
          taps: 0,
          impressions: 0,
          ttr: 0,
          newDownloads: 0,
          redownloads: 0,
          installs: 0,
          localSpend: {
            amount: 0,
          },
        };
      },
    },
  },
  data() {
    return {};
  },

  computed: {
    summaryItems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('summary.taps'),
            key: 'taps',
            tooltip: 'The number of times your ad was tapped on.',
          },
          {
            title: this.$t('summary.impressions'),
            key: 'impressions',
            tooltip: 'The number of times you have appeared as an ad.',
          },
          {
            title: this.$t('summary.ttr'),
            key: 'ttr',
            tooltip:
              'Tap Through Rate- This is calculated by dividing your total tap numbers to your total impression numbers.',
          },
          {
            title: this.$t('summary.newDownloads'),
            key: 'newDownloads',
            tooltip: 'Displays the number of first-time downloads of the app',
          },
          {
            title: this.$t('summary.reDownloads'),
            key: 'redownloads',
            tooltip:
              'Displays the number of downloads of the app by the same user',
          },
          {
            title: this.$t('summary.installs'),
            key: 'installs',
            tooltip:
              'The total number of downloads including new downloads + re-downloads',
          },
        ];
      },
    },
  },
  methods: {
    getValue(key: string): string {
      return this.values[key];
    },
  },
});
