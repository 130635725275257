































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import {
  DELETE_ADGROUP_NEG_KEY,
  DELETE_CAMPAIGN_NEG_KEY,
} from 'src/utils/apollo-mutations';
import Vue from 'vue';
export default Vue.extend({
  props: {
    negKeyDeletionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    negKeyDeletionData() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteNegKey() {
      this.deleting = true;
      try {
        if (this.negKeyDeletionData.adgroupId != null) {
          const result = await this.$apollo.mutate({
            mutation: DELETE_ADGROUP_NEG_KEY,
            variables: {
              orgId: decodeToken(LSS.token).parentOrgIds[0],
              campaignId: this.negKeyDeletionData.campaignId,
              adgroupId: this.negKeyDeletionData.adgroupId,
              keywordId: [this.negKeyDeletionData.keywordId],
            },
          });

          if (!result.data.deleteAdgroupNegKey.successMessage) {
            this.$notify.error('Error while deleting keyword');
          } else {
            this.$notify.success(
              'Keyword Deleted. Keyword moved to deleted keywords'
            );
            this.$emit('onDeletion');
          }
        } else {
          const result = await this.$apollo.mutate({
            mutation: DELETE_CAMPAIGN_NEG_KEY,
            variables: {
              orgId: decodeToken(LSS.token).parentOrgIds[0],
              campaignId: this.negKeyDeletionData.campaignId,
              keywordId: [this.negKeyDeletionData.keywordId],
            },
          });

          if (!result.data.deleteCampaignNegKey.successMessage) {
            this.$notify.error('Error while deleting keyword');
          } else {
            this.$notify.success(
              'Keyword Deleted. Keyword moved to deleted keywords'
            );
            this.$emit('onDeletion');
          }
        }
        this.deleting = false;
        this.dialog = false;
      } catch (error) {
        this.deleting = false;
        this.$notify.error('Error while deleting keyword');
      }
    },
  },
});
