














































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import { COUNTRIES } from 'src/utils/constants';
import { UPDATE_ADGROUP } from 'src/utils/apollo-mutations';
import { CustomerTypes, Genders } from 'src/utils/enums';
import { LSS } from '@core/services';
import { APPS_BY_ORG } from 'src/utils/apollo-queries';
import { CampaignsService } from '@services/campaigns.service';

export default Vue.extend({
  name: 'EditAdgroup',
  data() {
    return {
      show: false,
      dialog2: false,
      loading: false,
      savingUpdates: false,
      selectedLocalities: [],
      countries: COUNTRIES,
      adgroupDetails: {
        name: null,
        defaultBidAmount: {
          amount: null,
        },
        cpaGoal: {
          amount: null,
        },
        age: {
          included: {
            minAge: null,
            maxAge: null,
          },
        },
        startTime: null,
        endTime: null,
        automatedKeywordsOptIn: null,
        selectedCustomerType: CustomerTypes.ALL,
        targetingDimensions: {
          adminArea: {
            included: null,
          },
          gender: {
            included: null,
          },
          locality: {
            included: null,
          },
          deviceClass: {
            included: null,
          },
          appDownloaders: {
            included: null,
            excluded: null,
          },
          country: {
            included: null,
          },
          daypart: {
            userTime: {
              included: null,
            },
          },
        },
      },
      cpaGoalAmount: null,
      cpaGoalCurrency: null,
      country: null,
      selectedGender: [null],
      selectedDeviceClass: null,
      selectedMinAge: null,
      selectedMaxAge: null,
      selectedStartTime: null,
      selectedEndTime: null,
      searchLoc: null,
      locations: [],
      tabs: null,
      items: ['Edit'],

      datePickerProps: {
        min: new Date().toISOString(),
      },

      onClickShow: false,
      Devices: [
        { text: 'IPHONE', value: 'IPHONE' },
        { text: 'IPAD', value: 'IPAD' },
      ],

      ageRange: false,
      ageRangeValue: [this.selectedMinAge, this.selectedMaxAge],

      //Validation
      valid: true,
      nameRules: [(v) => !!v || 'Adgroup Name is Required'],
      amountRules: [
        (v) => !!v || 'Default Max CPT Bid is required',
        (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
      ],
    };
  },

  props: {
    adgroupData: {
      type: Object,
      default() {
        return;
      },
    },
  },

  computed: {
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
  },

  watch: {
    adgroupData: function (data) {
      this.show = true;

      if (data.targetingDimensions.country == null) {
        this.country = null;
      } else {
        this.country = data.targetingDimensions.country.included;
      }

      if (data.targetingDimensions.gender == null) {
        this.selectedGender = Genders.ALL;
      } else {
        this.selectedGender = data.targetingDimensions.gender.included[0];
      }

      if (data.targetingDimensions.deviceClass == null) {
        this.selectedDeviceClass = null;
      } else {
        this.selectedDeviceClass =
          data.targetingDimensions.deviceClass.included;
      }

      if (data.targetingDimensions.age == null) {
        this.ageRange = false;
      } else {
        this.ageRange = true;
        this.selectedMinAge = data.targetingDimensions.age.included[0].minAge;
        this.selectedMaxAge = data.targetingDimensions.age.included[0].maxAge;
        this.ageRangeValue = [this.selectedMinAge, this.selectedMaxAge];
      }

      if (data.endTime == null) {
        this.selectedEndTime = null;
      } else {
        this.selectedEndTime = new Date(data.endTime);
      }

      if (data.startTime == null) {
        this.selectedStartTime = null;
      } else {
        this.selectedStartTime = new Date(data.startTime);
      }

      if (data.cpaGoal == null) {
        this.cpaGoalAmount = null;
      } else {
        this.cpaGoalAmount = data.cpaGoal.amount;
        this.cpaGoalCurrency = data.cpaGoal.currency;
      }

      this.selectedCustomerType = CustomerTypes.ALL;

      this.adgroupDetails = _.cloneDeep({
        name: data.name,
        defaultBidAmount: {
          amount: data.defaultBidAmount.amount,
          currency: data.defaultBidAmount.currency,
        },
        // cpaGoal: {
        //   amount: data.cpaGoal.amount,
        //   currency: data.cpaGoal.currency,
        // },

        startTime: data.startTime,
        endTime: data.endTime,
        automatedKeywordsOptIn: data.automatedKeywordsOptIn,
        targetingDimensions: data.targetingDimensions,
      });
    },

    searchLoc(query: string): void {
      query &&
        query.length > 2 &&
        this.searchLocations(query, this.adgroupData.countriesOrRegions[0]);
    },
  },

  methods: {
    async updateAdgroup() {
      this.savingUpdates = true;
      const orgId = this.adgroupData.orgId;
      const adamId = this.adgroupData.adamId;
      const allOwnedApps = this.apps.map((app) => app.adamId);
      const adgroupRequest = {
        name: this.adgroupDetails.name,
        automatedKeywordsOptIn: this.adgroupDetails.automatedKeywordsOptIn,
        defaultBidAmount: this.adgroupDetails.defaultBidAmount,
        ...(this.cpaGoalAmount && {
          cpaGoal: {
            amount: this.cpaGoalAmount,
            currency: this.cpaGoalCurrency,
          },
        }),
        ...(this.selectedEndTime && { endTime: this.selectedEndTime }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectedDeviceClass,
          },
          ...((this.ageRange && {
            age: {
              included: [
                {
                  minAge: this.ageRangeValue[0],
                  maxAge: this.ageRangeValue[1],
                },
              ],
            },
          }) || {
            age: null,
          }),
          // gender criteria
          ...((this.selectedGender && {
            gender: {
              included: [this.selectedGender],
            },
          }) || {
            gender: null,
          }),
          country: {
            included: this.adgroupData.countriesOrRegions,
          },
          // TODO
          adminArea: null,
          daypart: null,
          ...((this.selectedLocalities &&
            this.selectedLocalities.length && {
              locality: {
                included: this.selectedLocalities,
              },
            }) || {
            locality: null,
          }),
          // App downloader criteria (customer type drop-down)
          ...(this.selectedCustomerType === CustomerTypes.ALL && {
            appDownloaders: null,
          }),
          ...(this.selectedCustomerType === CustomerTypes.NEW && {
            appDownloaders: {
              excluded: [adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
            appDownloaders: {
              included: [adamId.toString()],
            },
          }),
          ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
            appDownloaders: {
              included: [...allOwnedApps.map((adamId) => adamId.toString())],
            },
          }),
        },
      };
      const campaignId = this.adgroupData.campaignId;
      const adgroupId = this.adgroupData.adgroupId;
      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_ADGROUP,
          variables: {
            orgId,
            campaignId,
            adgroupId,
            adgroupRequest,
          },
        });
        this.savingUpdates = false;

        if (result.data.updateAdGroup.searchAdsErrors) {
          this.$notify.error(result.data.updateAdGroup.searchAdsErrors);
        } else {
          this.$notify.success('AdGroup updated.');
          this.close();
        }
      } catch (error) {
        this.savingUpdates = false;
      }
    },

    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      this.locations = await CampaignsService.searchedLocations(
        this.adgroupData.orgId,
        locationName,
        countrycode
      );
      this.loading = false;
    },

    close() {
      this.show = false;
      this.dialog2 = false;
    },
  },

  apollo: {
    apps: {
      query: APPS_BY_ORG,
      variables() {
        return {
          orgId: this.adgroupData && this.adgroupData.orgId,
        };
      },
      skip() {
        return !(this.adgroupData && this.adgroupData.orgId);
      },
    },
  },
});
