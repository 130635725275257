













































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import { CampaignsService } from '@services/campaigns.service';
import * as _ from 'lodash';
import { UPDATE_CREATIVE_SET } from 'src/utils/apollo-mutations';
import Vue from 'vue';

export default Vue.extend({
  name: 'EditCreativeSet',
  data() {
    return {
      updatingCs: false,
      updateCreativeSetDialog: false,
      allImgs: {},
      csName: null,
      creativeSetId: null,
      orgId: null,
      selectedLanguage: [],
      language: [],
      displayLanguage: null,
      creativeappassets: null,
      defaultImages: [],
      defaultGenIds: [],
    };
  },

  props: {
    openUpdateCreativeSetData: {
      type: Object,
      default() {
        return;
      },
    },
  },

  watch: {
    openUpdateCreativeSetData: async function (data) {
      this.updateCreativeSetDialog = true;
      this.csName = data.csName;
      this.creativeSetId = data.creativeSetId;
      this.orgId = data.orgId;
      this.language = data.language;


      this.creativeappassets = await CampaignsService.creativeappassets(data.orgId, data.adamId, data.countries);
      const enUs = this.creativeappassets.creativeSetDetails[this.language];
      this.displayLanguage = enUs.languageDisplayName;
      this.assignImages(enUs.appPreviewDeviceWithAssets);
    },
  },

  methods: {
    async updateCreativeSet() {
      this.updatingCs = true;
      const updateData = {
        name : this.csName
      }

      try {
        const result = await this.$apollo.mutate({
        mutation: UPDATE_CREATIVE_SET,
        variables: {
          orgId: this.orgId,
          creativeSetId: this.creativeSetId,
          creativeSetUpdateReq: updateData,
        },
      });

      if(result.data.updateCreativeSet.searchAdsErrors) {
        this.$notify.error(result.data.updateCreativeSet.searchAdsErrors);
      } else {
        this.$notify.success('Creative set updated.')
        this.close();
      }

      } catch {}
      this.updatingCs = false;
    },

    assignImages(object) {
      this.allImgs['tabs'] = []; //add tabs property to allImgs object, this will contain tab names
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          this.allImgs['tabs'].push(key); // for each key, push it into allImgs['tabs']
          this.allImgs[key] = []; //add key itself as a property
          const imgs = object[key].screenshots; //get screenshot details for each key
          imgs.forEach((element) => {
            const temp = {
              //create object with url and genId and push it into allImgs
              assetURL: element.assetURL,
              assetGenId: element.assetGenId,
            };
            this.allImgs[key].push(temp);
          });
          this.defaultImages.push(imgs[0].assetURL);
          this.defaultGenIds.push(imgs[0].assetGenId);
        }
      }
    },

    close() {
      this.updateCreativeSetDialog = false;
      this.csName = null;
    },
  },
});
