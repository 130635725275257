





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as moment from 'moment';
import * as _ from 'lodash';
import Vue from 'vue';
import DataTable from '../components/DataTable.vue';
import TotalSummary from '../components/ads-manager/TotalSummary.vue';
import Chart from '../components/ads-manager/Chart.vue';
import EditCampaign from '../components/campaign/EditCampaign.vue';
import AddKeywords from '../components/keywords/AddKeywords.vue';
import AddAdGroup from '../components/adgroup/AddAdGroup.vue';
import SelectAdGroup from '../components/keywords/SelectAdGroup.vue';
import EditAdGroup from '../components/adgroup/EditAdGroup.vue';
import AddCreativeSet from '../components/campaign/AddCreativeSet.vue';
import AddNegativeKeywords from '../components/negativekeywords/AddNegativeKeywords.vue';
import EditBidAmount from '../components/keywords/EditBidAmount.vue';
import EditCreativeSet from 'src/components/creativeset/EditCreativeSet.vue';
import DeleteCampaign from '../components/campaign/DeleteCampaign.vue';
import DeleteNegKey from '../components/negativekeywords/DeleteNegKey.vue';
import {
  AdsManagerTabs,
  AdsManagerFilters,
  AdGroupSelectorContext,
  BusEvents,
} from '../utils/enums';
import { DEFAULT_GRANULARITY, LS_DRAFT_KEY } from '@shared/config';
import {
  APPS_GRAPH,
  APPS_REPORTS,
  CAMPAIGN_REPORTS,
  CAMPAIGN_GRAPH,
  SEARCH_TERMS_REPORTS,
  CREATIVE_SET_REPORTS,
  TARGETING_KEYWORD_REPORTS,
  NEGATIVE_KEYWORD_REPORTS,
  GET_CAMPAIGN_GROUPS,
  ACCOUNTS_REPORTS,
  AD_GROUP_REPORTS,
  KEYWORDS_GRAPH,
  AD_GROUP_GRAPH,
  CREATIVE_SET_GRAPH,
  SEARCH_TERMS_GRAPH,
  ACCOUNTS_GRAPH,
  GET_CAMPAIGN_DRAFTS,
  ACCOUNTS_REPORTS_FOR_FILTER,
  APPS_REPORTS_FOR_FILTER,
  CAMPAIGN_REPORTS_FOR_FILTER,
  AD_GROUP_REPORTS_FOR_FILTER,
} from '../utils/apollo-queries';
import {
  ADD_CREATIVE_SET,
  TOGGLE_ADGROUP,
  TOGGLE_CREATIVE_SET,
  TOGGLE_CAMPAIGNS,
  TARGET_KEYWORD_STATUS,
} from '../utils/apollo-mutations';
import { updateCLF } from '../utils/util';
import { bus } from '../main';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    TotalSummary,
    Chart,
    DataTable,
    EditCampaign,
    AddKeywords,
    SelectAdGroup,
    AddCreativeSet,
    AddAdGroup,
    EditAdGroup,
    AddNegativeKeywords,
    EditBidAmount,
    EditCreativeSet,
    DeleteCampaign,
    DeleteNegKey,
  },
  props: {},
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    const day2MonthsAgo = moment()
      .utc()
      .subtract(2, 'months')
      .format('YYYY-MM-DD');
    return {
      attrs: null,
      on: null,
      syncing: false,
      // tabs variables
      accounts_tab: true,
      apps_tab: false,
      campaigns_tab: false,
      ad_groups_tab: false,
      keywords_tab: false,
      creative_sets_tab: false,
      search_terms_tab: false,
      neg_keywords_tab: false,

      adsManagerFilters: AdsManagerFilters,

      currentTab: AdsManagerTabs.ACCOUNTS,
      select: '',
      drawer: false,
      selectedAccount: 0,
      selectedApp: 0,
      selectedCampaign: 0,
      selectedAdGroup: 0,
      selectedTargetingKeyword: 0,
      filter: {
        dateRange: [day2MonthsAgo, today],
      },
      graphFilter: {
        granularity: DEFAULT_GRANULARITY,
        dateRange: [day2MonthsAgo, today],
      },
      chartGranularity: DEFAULT_GRANULARITY,
      timeProps: {
        outlined: true,
        dense: true,
      },
      mini: true,
      e1: 1,
      checkbox: true,
      switch1: true,

      row: null,
      radioGroup: 1,

      //Search Filter
      keyFilter: '',

      filteredAccountReports: null,
      accountsReport: null,

      filteredAppReports: null,
      appsReport: null,

      filteredCampaignReports: null,
      campaignReport: null,

      filteredAdgroupReports: null,
      adgroupReport: null,

      filteredKeywordReports: null,
      keywordReport: null,

      filteredCreativeSetReports: null,
      creativeSetReport: null,

      filteredSearchTermsReports: null,
      searchTermsReport: null,

      filteredNegKeywordReports: null,
      negKeywordReport: null,

      //Reporting Sub Tabs Data
      liveCampaignReports: null,
      disabledCampaignReports: null,
      deletedCampaignReports: null,

      disabledAdgroupReports: null,
      deletedAdgroupReports: null,

      disabledKeywordReports: null,
      deletedKeywordReports: null,

      disabledCreativeSetReports: null,
      deletedCreativeSetReports: null,

      disabledNegKeywordReports: null,
      deletedNegKeywordReports: null,

      liveReportsCount: 0,
      disabledReportsCount: 0,
      deletedReportsCount: 0,
      draftReportsCount: 0,

      filteredAdgroupReportsCount: 0,
      disabledAdgroupReportsCount: 0,
      deletedAdgroupReportsCount: 0,

      filteredKeywordReportsCount: 0,
      disabledKeywordReportsCount: 0,
      deletedKeywordReportsCount: 0,

      filteredCreativeSetReportsCount: 0,
      disabledCreativeSetReportsCount: 0,
      deletedCreativeSetReportsCount: 0,

      filteredNegKeywordReportsCount: 0,
      disabledNegKeywordReportsCount: 0,
      deletedNegKeywordReportsCount: 0,

      tab: null,
      campaignListingTab: null,
      adgroupListingTab: null,
      keywordListingTab: null,
      creativeListingTab: null,
      negKeyListingTab: null,
      switchLoading: false,

      loading: true,
      filterDateRange: [day2MonthsAgo, today],
      rangePicker: false,
      modal: false,
      menu2: false,

      // for editing campaign
      campaignMetadata: null,
      // for campaign deletion
      campaignDeletionMetadata: null,
      // for adding keywords
      adgroupMetadata: null,
      // editing adgroup
      editAdgroupMetadata: null,
      // adgroup selection for keywords
      selectAdgroupForKeywords: null,
      // adgroup selection for negative keyword
      openAddNegativeKeywords: null,
      // adgroup selection for creative set
      selectAdgroupForCs: null,
      // for adding creative set
      creativeSetMetadata: null,
      // for editing bid amount
      keywordMetaData: null,
      //for adding adgroup
      openAddAdgroup: null,
      //for editing creativeSet
      updateCreativeSetData: null,
      //for Editing adgroup
      openEditAdgroup: null,
      //for Deleting Neg Key
      negKeyDeletionData: null,

      campaignChartData: {
        labels: [],
        datasets: [],
      },
      appsChartData: {
        labels: [],
        datasets: [],
      },
      keywordsChartData: {
        labels: [],
        datasets: [],
      },
      adGroupChartData: {
        labels: [],
        datasets: [],
      },
      creativeSetChartData: {
        labels: [],
        datasets: [],
      },
      searchTermsChartData: {
        labels: [],
        datasets: [],
      },

      accountsChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
      chartDataSets: [],

      panel: [],

      selectedItem: 1,
      listitems: [
        // { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Test', icon: 'mdi-circle-small' },
        { text: 'MyCamp2', icon: 'mdi-circle-small' },
        { text: 'MyCamp3', icon: 'mdi-circle-small' },
        { text: 'MyCamp4', icon: 'mdi-circle-small' },
      ],

      search: '',
      headers: [
        {
          text: 'addKeyword',
          value: 'addKeyword',
        },
        { text: 'Keyword', value: 'keyword' },
        { text: 'Popularity', value: 'popularity' },
        { text: 'Ranking', value: 'ranking' },
      ],
    };
  },

  apollo: {
    appsChart: {
      query: APPS_GRAPH,
      skip() {
        return !this.apps_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.appsChart, 'appsChartData');
      },
    },
    campaignChart: {
      query: CAMPAIGN_GRAPH,
      skip() {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.campaignChart, 'campaignChartData');
      },
    },

    appsReports: {
      query: APPS_REPORTS,
      skip(): boolean {
        return !this.apps_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.filteredAppReports = data.appsReports;
        this.appsReport = data.appsReports;
      },
    },

    campaignReports: {
      query: CAMPAIGN_REPORTS,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.campaignReport = data.campaignReports;
        this.deletedCampaignReport('');
        this.disabledCampaignReport('');
        this.liveCampaignReport('');
      },
    },

    getCampaignDrafts: {
      query: GET_CAMPAIGN_DRAFTS,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      fetchPolicy: 'no-cache',
    },

    searchTermsGraph: {
      query: SEARCH_TERMS_GRAPH,
      skip(): boolean {
        return !this.search_terms_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.searchTermsChart, 'searchTermsChartData');
      },
    },

    searchTermsReports: {
      query: SEARCH_TERMS_REPORTS,
      skip(): boolean {
        return !this.search_terms_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.searchTermsReport = data.searchTermsReports;
        this.filteredSearchTermsReports = data.searchTermsReports;
      },
    },

    creativeSetGraph: {
      query: CREATIVE_SET_GRAPH,
      skip(): boolean {
        return !this.creative_sets_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.creativeSetChart, 'creativeSetChartData');
      },
    },

    creativeSetReports: {
      query: CREATIVE_SET_REPORTS,
      skip(): boolean {
        return !this.creative_sets_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.creativeSetReport = data.creativeSetReports;
        this.deletedCreativeSetReport('');
        this.disabledCreativeSetReport('');
        this.creativesetSearchFilter('');
      },
    },

    keywordsGraph: {
      query: KEYWORDS_GRAPH,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.keywordsChart, 'keywordsChartData');
      },
    },

    targetingKeywordReports: {
      query: TARGETING_KEYWORD_REPORTS,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.keywordReport = data.targetingKeywordReports;
        this.deletedKeywordReport('');
        this.disabledKeywordReport('');
        this.keywordSearchFilter('');
      },
    },

    negativeKeywordReports: {
      query: NEGATIVE_KEYWORD_REPORTS,
      skip(): boolean {
        return !this.neg_keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.negKeywordReport = data.negativeKeywordReports;
        this.deletedNegKeywordReport('');
        this.disabledNegKeywordReport('');
        this.negKeywordSearchFilter('');
      },
    },

    accountsGraph: {
      query: ACCOUNTS_GRAPH,
      skip() {
        return !this.accounts_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.accountsChart, 'accountsChartData');
      },
    },

    accountsReports: {
      query: ACCOUNTS_REPORTS,
      skip(): boolean {
        return !this.accounts_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.accountsReport = data.accountsReports;
        this.filteredAccountReports = data.accountsReports;
      },
    },

    adGroupGraph: {
      query: AD_GROUP_GRAPH,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.adGroupChart, 'adGroupChartData');
      },
    },

    adGroupReports: {
      query: AD_GROUP_REPORTS,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.adgroupReport = data.adGroupReports;
        this.deletedAdgroupReport('');
        this.disabledAdgroupReport('');
        this.adgroupSearchFilter('');
      },
    },

    //----------Queries for Filter Data Start--------------

    accountsReportsForFilter: {
      query: ACCOUNTS_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: this.filter,
        };
      },
    },

    appsReportsForFilter: {
      query: APPS_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
            },
            dateRange: this.filterDateRange,
          },
        };
      },
    },

    campaignReportsForFilter: {
      query: CAMPAIGN_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
            },
            dateRange: this.filterDateRange,
          },
        };
      },
    },

    adgroupReportsForFilter: {
      query: AD_GROUP_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
            },
            dateRange: this.filterDateRange,
          },
        };
      },
    },

    //----------Queries for Filter Data End--------------
  },

  computed: {
    tabItems: {
      cache: false,
      get() {
        return [
          {
            tab: this.$t('tabs.accounts'),
            content: 'Accounts 1 Content',
            tabKey: AdsManagerTabs.ACCOUNTS,
            filters: [],
          },
          {
            tab: this.$t('tabs.apps'),
            content: 'Apps 2 Content',
            tabKey: AdsManagerTabs.APPS,
            filters: [AdsManagerFilters.ACCOUNT],
          },
          {
            tab: this.$t('tabs.campaigns'),
            content: 'Campaigns 3 Content',
            tabKey: AdsManagerTabs.CAMPAIGNS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS],
          },
          {
            tab: this.$t('tabs.adgroups'),
            content: 'Ad Groups 4 Content',
            tabKey: AdsManagerTabs.AD_GROUPS,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
            ],
          },
          {
            tab: this.$t('tabs.keywords'),
            content: 'Keywords 5 Content',
            tabKey: AdsManagerTabs.KEYWORDS,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
              AdsManagerFilters.ADGROUP,
            ],
          },
          {
            tab: this.$t('tabs.creativeSets'),
            content: 'Creative Sets 6 Content',
            tabKey: AdsManagerTabs.CREATIVE_SETS,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
              AdsManagerFilters.ADGROUP,
            ],
          },
          {
            tab: this.$t('tabs.searchTerms'),
            tabKey: AdsManagerTabs.SEARCH_TERMS,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
              AdsManagerFilters.ADGROUP,
            ],
          },
          {
            tab: this.$t('tabs.negKeywords'),
            content: 'Negative Keywords 7 Content',
            tabKey: AdsManagerTabs.NEG_KEYWORDS,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
              AdsManagerFilters.ADGROUP,
            ],
          },
        ];
      },
    },

    //Data Tables
    // 1-Accounts Data Table
    accountHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('accountHeaders.account'),
            align: 'start',
            value: 'orgMetadata.orgName',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('accountHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
        ];
      },
    },

    // 2-Apps Data Table
    appsHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('appsHeaders.appName'),
            align: 'start',
            value: 'metadata.app.appName',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.attrInstall'),
            value: 'attrinstalls',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.attrInstallRate'),
            value: 'attrinstallsrate',
            sortable: false,
          },
          { text: this.$t('appsHeaders.cpi'), value: 'cpi', sortable: false },
          {
            text: this.$t('appsHeaders.goals'),
            value: 'goal',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.costPerGoal'),
            value: 'costpergoal',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.goalRate'),
            value: 'goalsrate',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.revenuePerGoal'),
            value: 'revenuepergoal',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.goalsRevenue'),
            value: 'goalsrevenue',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.goalsRoas'),
            value: 'goalsroas',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.categoryRank'),
            value: 'categoryrank',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.appId'),
            value: 'metadata.app.adamId',
            sortable: false,
          },
        ];
      },
    },

    // 3-Campaign Data Table
    campaignHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('campaignHeaders.campaign'),
            align: 'start',
            sortable: false,
            value: 'campaignMetadata.name',
          },
          {
            text: this.$t('campaignHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.displayStatus'),
            value: 'campaignMetadata.displayStatus',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.delete'),
            value: 'campaignMetadata.deleted',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.attrInstallRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.cpi'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.goals'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.costPerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.goalRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.revenuePerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.goalsRoas'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
          },

          // { text: 'Ad Group Status', value: 'adgroupMetadata.status' , sortable: false},
          // { text: 'Ad Group Lat On Factor', value: 'conversion' , sortable: false},
          // {
          //   text: 'Search Match',
          //   value: 'adgroupMetadata.automatedKeywordsOptIn',
          //   sortable: false
          // },
          // {
          //   text: 'Deafult CPC Bid',
          //   value: 'adgroupMetadata.defaultBidAmount.amount',
          //   sortable: false
          // },
          {
            text: this.$t('campaignHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
          },

          // { text: 'Age Range', value: 'conversion' , sortable: false},
          // {
          //   text: 'Gender',
          //   value: 'adgroupMetadata.targetingDimensions.gender.included',
          //   sortable: false
          // },
          // {
          //   text: 'Device',
          //   value: 'adgroupMetadata.targetingDimensions.deviceClass.included',
          //   sortable: false
          // },
          // { text: 'Customer Type', value: 'conversion' , sortable: false},
          {
            text: this.$t('campaignHeaders.appName'),
            value: 'metadata.app.appName',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.appId'),
            value: 'metadata.app.adamId',
            sortable: false,
          },
          {
            text: this.$t('campaignHeaders.campaignId'),
            value: 'campaignMetadata.id',
            sortable: false,
          },
          // { text: 'Ad Group ID', value: 'adgroupMetadata.id' , sortable: false},
        ];
      },
    },

    // 3(i)- Campaign Draft Data Table
    campaignDraftHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('campaignDraftHeaders.campaignName'),
            value: 'campaign.name',
            sortable: false,
          },
          {
            text: this.$t('campaignDraftHeaders.countries'),
            value: 'campaign.countriesOrRegions',
            sortable: false,
          },
          {
            text: this.$t('campaignDraftHeaders.budgetAmount'),
            value: 'campaign.budgetAmount.amount',
            sortable: false,
          },
          {
            text: this.$t('campaignDraftHeaders.resume'),
            value: 'resume',
            sortable: false,
          },
        ];
      },
    },

    // 4- Adgroup Data Table
    adGroupHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('adGroupHeaders.adgroup'),
            align: 'start',
            value: 'adgroupMetadata.name',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.attrInstallRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.cpi'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.goals'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.costPerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.goalRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.revenuePerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.goalsRoas'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.displayStatus'),
            value: 'adgroupMetadata.displayStatus',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.appId'),
            value: 'campaignMetadata.adamId',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.campaignId'),
            value: 'adgroupMetadata.campaignId',
            sortable: false,
          },
          {
            text: this.$t('adGroupHeaders.adgroupId'),
            value: 'adgroupMetadata.id',
            sortable: false,
          },
        ];
      },
    },

    // 5- Keyword Data Table
    keyheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('keyHeaders.keyword'),
            align: 'start',
            value: 'keywordMetadata.text',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.keywordStatus'),
            value: 'keywordMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.bidAmount'),
            value: 'keywordMetadata.bidAmount.amount',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.attrInstallRate'),
            value: 'attrinstallsrate',
            sortable: false,
          },
          { text: this.$t('keyHeaders.cpi'), value: 'cpi', sortable: false },
          { text: this.$t('keyHeaders.goals'), value: 'goal', sortable: false },
          {
            text: this.$t('keyHeaders.costPerGoal'),
            value: 'costpergoal',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.goalRate'),
            value: 'goalsrate',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.revenuePerGoal'),
            value: 'revenuepergoal',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.goalsRevenue'),
            value: 'goalsrevenue',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.organicRank'),
            value: 'organicrank',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.appName'),
            value: 'metadata.app.appName',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.difficultyScore'),
            value: 'difficulty',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.popularity'),
            value: 'popularity',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.keywordId'),
            value: 'keywordMetadata.id',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.appId'),
            value: 'campaignMetadata.adamId',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.campaignId'),
            value: 'metadata.campaignId',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.adgroupId'),
            value: 'keywordMetadata.adGroupId',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.suggestedMinBid'),
            value: 'insights.bidRecommendation.bidMin.amount',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.suggestedMaxBid'),
            value: 'insights.bidRecommendation.bidMax.amount',
            sortable: false,
          },
          {
            text: this.$t('keyHeaders.bidStrength'),
            value: 'bidstrength',
            sortable: false,
          },
        ];
      },
    },

    // 6- Creative Set Data Table
    creativeheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('creativeHeaders.creativeSet'),
            align: 'start',
            value: 'creativeSetMetadata.name',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.creativeSetStatus'),
            value: 'creativeSetMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.attrInstallRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.cpi'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.goals'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.costPerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.goalRate'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.revenuePerGoal'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.goalsRevenue'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.appId'),
            value: 'campaignMetadata.adamId',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.campaignId'),
            value: 'campaignMetadata.id',
            sortable: false,
          },
          {
            text: this.$t('creativeHeaders.adgroupId'),
            value: 'adgroupMetadata.id',
            sortable: false,
          },
        ];
      },
    },

    // 7- Search Term Data Table
    searchTermsHeader: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('searchTermsHeader.searchTerm'),
            align: 'start',
            value: 'metadata.searchTermText',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.averageCpa'),
            value: 'total.avgCPA.amount',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.installs'),
            value: 'total.installs',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.conversionRate'),
            value: 'total.conversionRate',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.newDownloads'),
            value: 'total.newDownloads',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.reDownloads'),
            value: 'total.redownloads',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.storefronts'),
            value: 'storefronts',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.campaignStatus'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.adgroupStatus'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.keyword'),
            value: 'metadata.keyword',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.keywordId'),
            value: 'metadata.keywordId',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.appId'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.campaignId'),
            value: 'conversion',
            sortable: false,
          },
          {
            text: this.$t('searchTermsHeader.adgroupId'),
            value: 'metadata.adGroupId',
            sortable: false,
          },
        ];
      },
    },

    // 8- Negative Keyword Data Table
    negativeHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('negativeHeaders.keyword'),
            value: 'text',
            align: 'start',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.matchType'),
            value: 'matchType',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.status'),
            value: 'status',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.adgroupId'),
            value: 'adGroupId',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.campaignId'),
            value: 'campaignId',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.deleted'),
            value: 'deleted',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.keywordId'),
            value: 'id',
            sortable: false,
          },
          {
            text: this.$t('negativeHeaders.modificationTime'),
            value: 'modificationTime',
            sortable: false,
          },
          {
            text: this.$t('keyOpt.action'),
            value: 'Action',
            sortable: false,
          },
        ];
      },
    },
    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      return this.filterDateRange.join(' - ');
    },
  },

  methods: {
    async onCampaignStatusChange(value, item) {
      item.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_CAMPAIGNS,
          variables: {
            orgId: item.campaignMetadata.orgId,
            campaignId: item.campaignMetadata.id,
            campaignStatus: { status: value },
          },
        });

        if (!result.data.enableDisableCampaign.successMessage) {
          throw this.$notify.error("Couldn't change campaign status.");
        } else {
          this.$notify.success('Status Changed. Campaign moved to other Tab');
        }
        item.loading = false;
      } catch (error) {
        item.campaignMetadata.status =
          value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
        item.loading = false;
      }
    },

    openCampaignDraft(item) {
      localStorage.setItem(LS_DRAFT_KEY, item._id);
      this.$router.push('create-campaign');
    },

    openDeleteCampaignDialog({ campaignMetadata }) {
      this.campaignDeletionMetadata = {
        campaignId: campaignMetadata.id,
        orgId: campaignMetadata.orgId,
        now: Date.now(),
      };
    },

    onDeleteCampaign() {
      this.refetchData();
    },

    openEditCampaignWizard(data) {
      const metadata = data.campaignMetadata;
      const reportMetadata = data.metadata;
      const campaignDetails = {
        orgId: metadata.orgId,
        campaignId: metadata.id,
        name: metadata.name,
        countriesOrRegions: metadata.countriesOrRegions,
        app: reportMetadata.app,
        budgetAmount: metadata.budgetAmount,
        dailyBudgetAmount: metadata.dailyBudgetAmount,
        now: Date.now(),
      };
      this.campaignMetadata = campaignDetails;
    },

    openSelectAdgroupForKeywords(data) {
      this.selectAdgroupForKeywords = {
        selectedAdgroup: data,
        context: AdGroupSelectorContext.KEYWORD,
        now: Date.now(),
      };
    },

    openAddNegativeKeyword() {
      this.openAddNegativeKeywords = {
        now: Date.now(),
      };
    },

    openDeleteNegKeyDialog(negKeyData) {
      this.negKeyDeletionData = {
        campaignId: negKeyData.campaignId,
        adgroupId: negKeyData.adGroupId,
        keywordId: negKeyData.id,
        now: Date.now(),
      };
    },

    onDeletingNegKey() {
      this.refetchData();
    },

    openSelectAdgroupForCs() {
      this.selectAdgroupForCs = {
        context: AdGroupSelectorContext.CREATIVESET,
        now: Date.now(),
      };
    },

    openAddAdGroup() {
      this.openAddAdgroup = {
        now: Date.now(),
      };
    },

    openEditBidAmount(item) {
      const metadata = {
        orgId: item.adgroupMetadata.orgId,
        keywordId: item.keywordMetadata.id,
        campaignId: item.metadata.campaignId,
        adGroupId: item.keywordMetadata.adGroupId,
        bidAmount: item.keywordMetadata.bidAmount,
        now: Date.now(),
      };
      this.keywordMetaData = metadata;
    },

    async onAdgroupStatusChange(value, item) {
      item.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_ADGROUP,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            campaignId: item.adgroupMetadata.campaignId,
            adgroupId: item.adgroupMetadata.id,
            adGroupStatus: { status: value },
          },
        });

        if (!result.data.enableDisableAdGroup.successMessage) {
          throw this.$notify.error("Couldn't update adgroup status.");
        } else {
          this.$notify.success('Status changed. Adgroup moved to other tab');
        }
      } catch (error) {
        item.adgroupMetadata.status =
          value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
      }
      item.loading = false;
    },

    openEditAddgroupWizard(data) {
      const metadata = data.adgroupMetadata;
      const adgroupDetails = {
        adamId: data.campaignMetadata.adamId,
        countriesOrRegions: data.campaignMetadata.countriesOrRegions,
        orgId: metadata.orgId,
        adgroupId: metadata.id,
        campaignId: metadata.campaignId,
        name: metadata.name,
        defaultBidAmount: metadata.defaultBidAmount,
        cpaGoal: metadata.cpaGoal,
        startTime: metadata.startTime,
        endTime: metadata.endTime,
        automatedKeywordsOptIn: metadata.automatedKeywordsOptIn,
        targetingDimensions: metadata.targetingDimensions,
        now: Date.now(),
      };
      this.editAdgroupMetadata = adgroupDetails;
    },

    async openUpdateCreativeSet(item) {
      this.updateCreativeSetData = {
        csName: item.creativeSetMetadata.name,
        creativeSetId: item.creativeSetMetadata.creativeSetId,
        orgId: item.adgroupMetadata.orgId,
        language: item.creativeSetMetadata.languageCode,
        adamId: item.campaignMetadata.adamId,
        countries: item.campaignMetadata.countriesOrRegions,
        now: Date.now(),
      };
    },

    async onCreativeSetStatusChange(value, item) {
      item.loading = true;
      const enablePauseCreativeSetReq = {
        adgroupId: item.creativeSetMetadata.adGroupId,
        campaignId: item.creativeSetMetadata.campaignId,
        creativeSetId: item.creativeSetMetadata.id,
        status: value,
      };
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_CREATIVE_SET,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            enablePauseCreativeSetReq,
          },
        });

        item.loading = false;
      } catch (error) {
        item.loading = false;
        item.creativeSetMetadata.status =
          value === 'VALID' ? 'INVALID' : 'VALID';
      }
    },

    async targetKeywordStatusChange(value, item) {
      item.loading = true;
      const changeTargetKeyStatusReq = {
        adgroupId: item.keywordMetadata.adGroupId,
        campaignId: item.adgroupMetadata.campaignId,
        status: value,
        keywordId: item.keywordMetadata.id,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: TARGET_KEYWORD_STATUS,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            changeTargetKeyStatusReq,
          },
        });

        item.loading = false;
        if (!result.data.changeTargetKeyStatus.successMessage) {
          this.$notify.success('Status changed. Keyword moved to other tab');
        } else {
          this.$notify.error("Couldn't update keyword status.");
          item.keywordMetaData.status =
            value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        }
      } catch (error) {
        item.loading = false;
        item.keywordMetaData.status = value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
      }
    },

    adgroupsSelectedForKeyword(appsWithCampaigns) {
      // getting adgroups
      const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
      this.adgroupMetadata = {
        selectedAdgroups: adgroups,
        now: Date.now(),
        selectedApp: appsWithCampaigns.selectedApp,
      };
    },

    addKeyToFiltAdgroup() {
      const data = [
        {
          orgId: this.selectedAdGroup.orgId,
          campaignId: this.selectedAdGroup.campaignId,
          id: this.selectedAdGroup.id,
          defaultBidAmount: this.selectedAdGroup.defaultBidAmount,
        },
      ];
      this.adgroupMetadata = { selectedAdgroups: data, now: Date.now() };
    },

    adgroupsSelectedForNegativeKeyword(appsWithCampaigns) {
      // getting adgroups
      const adgroups = this.getNested(appsWithCampaigns, 'ADGROUP');
      this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
    },

    adgroupsSelectedForCs(appsWithCampaigns) {
      const campaigns = this.getNested(appsWithCampaigns.data, 'CAMPAIGN');
      // in case of creative set, only one adgroup selection allowed.
      // hence it's safe to select the first campaign that has an adgroup
      const campaign = campaigns.filter(
        (campaign) => campaign.adgroups.length
      )[0];
      const adgroup = campaign.adgroups[0];

      this.creativeSetMetadata = {
        orgId: adgroup.orgId,
        adamId: campaign.adamId,
        adgroupId: adgroup.id,
        campaignId: campaign.id,
        countries: campaign.countriesOrRegions,
        now: Date.now(),
      };
    },

    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups)
                  )
            )
          );
    },

    async addCreativeSet({ creativeSet, orgId }) {
      const creativeSetReq = {
        creativeSet,
        orgId,
        adgroupId: this.creativeSetMetadata.adgroupId,
        campaignId: this.creativeSetMetadata.campaignId,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_CREATIVE_SET,
          variables: creativeSetReq,
        });

        if (result.data.addCreativeSet.searchAdsErrors) {
          this.$notify.error(result.data.addCreativeSet.searchAdsErrors);
        } else {
          this.$notify.success('Creative set added.');
          updateCLF('addCS', this);
        }
      } catch {}
    },

    setChartData(data, propName): void {
      data.datasets.map((item) => {
        item.label = _.startCase(item.label);
        item.fill = false;
        item.borderColor = this.getRandomColor();
        item.borderWidth = 1;
      });
      this[propName] = { ...data, time: Date.now().toString() };
    },

    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    handleGranularityChange(chartGranularity: string): void {
      this.chartGranularity = chartGranularity;

      this.graphFilter = this.createGraphFilters();
    },

    shouldFilterAppear(filter: string): boolean {
      const tabItem = this.tabItems.find(
        (item) => item.tabKey === this.currentTab
      );
      const allowedFilters = tabItem.filters;

      return allowedFilters.includes(filter);
    },

    async onTabChange(tabChangeEvent: any): void {
      //console.log(tabChangeEvent);
      this.currentTab = tabChangeEvent.tabKey;
      this.switchTabBoolean();
      this.resetFilters();
    },

    switchTabBoolean(): void {
      // falsify all
      Object.keys(AdsManagerTabs).map((key) => {
        this[AdsManagerTabs[key]] = false;
      });

      this[this.currentTab] = true;
    },

    applyDateRange() {
      // persisting filters that are already applied
      this.filter = Object.assign({}, this.filter, {
        dateRange: this.filterDateRange,
      });

      this.graphFilter = this.createGraphFilters();
      this.rangePicker = false;
    },

    createFilters(): any {
      return {
        metadata: {
          ...(this.selectedAccount && {
            orgId: this.selectedAccount.orgId,
          }),
          ...(this.selectedApp && {
            app: {
              adamId: this.selectedApp.adamId,
            },
          }),
          ...(this.selectedCampaign && {
            campaignId: this.selectedCampaign.id,
          }),
          ...(this.selectedAdGroup && {
            adGroupId: this.selectedAdGroup.id,
          }),
          ...(this.selectedTargetingKeyword && {
            keywordId: this.selectedTargetingKeyword,
          }),
        },

        dateRange: this.filterDateRange,
      };
    },

    createGraphFilters() {
      return {
        granularity: this.chartGranularity,
        dateRange: this.filterDateRange,
      };
    },

    applyGraphFilters() {
      const graphFilter = {
        ...this.filter,
        ...this.createGraphFilters(),
      };
      this.graphFilter = graphFilter;
    },

    applyFilters() {
      const filter = this.createFilters();
      this.filter = filter;
    },

    async runFilterQueries() {
      await this.applyFilters();
      await this.applyGraphFilters();
      this.drawer = false;
    },

    async resetFilters() {
      this.keyFilter = '';
      await this.applySearchFilter();
      this.selectedApp = 0;
      this.selectedAccount = 0;
      this.selectedCampaign = 0;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.filter = { dateRange: this.filterDateRange };
      this.graphFilter = this.createGraphFilters();
      this.drawer = false;
    },

    // Create an array the length of our items
    // with all values as true
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },

    async accountFilters(org) {
      this.tab = 1;
      await this.onTabChange(this.tabItems[1]);
      this.selectedAccount = org;
      await this.runFilterQueries();
    },

    async appFilters(org, appData) {
      this.tab = 2;
      await this.onTabChange(this.tabItems[2]);
      this.selectedAccount = org;
      this.selectedApp = appData.app;
      await this.runFilterQueries();
    },

    async campaignFilters(org, app, campaign) {
      this.tab = 3;
      await this.onTabChange(this.tabItems[3]);
      this.selectedAccount = org;
      this.selectedApp = app;
      this.selectedCampaign = { id: campaign.id, name: campaign.name };
      await this.runFilterQueries();
    },

    async adgroupFilters(org, app, campaign, adgroup) {
      this.tab = 4;
      await this.onTabChange(this.tabItems[4]);
      this.selectedAccount = org;
      this.selectedApp = app;
      this.selectedCampaign = { id: campaign.id, name: campaign.name };
      this.selectedAdGroup = adgroup; //{id : adgroup.id, name: adgroup.name};
      await this.runFilterQueries();
    },

    applySearchFilter() {
      const key = this.keyFilter;
      switch (this.tab) {
        case 0:
          this.accountsSearchFilter(key);
          break;

        case 1:
          this.appsSearchFilter(key);
          break;

        case 2:
          this.deletedCampaignReport(key);
          this.disabledCampaignReport(key);
          this.liveCampaignReport(key);
          break;

        case 3:
          this.deletedAdgroupReport(key);
          this.disabledAdgroupReport(key);
          this.adgroupSearchFilter(key);
          break;

        case 4:
          this.deletedKeywordReport(key);
          this.disabledKeywordReport(key);
          this.keywordSearchFilter(key);
          break;

        case 5:
          this.deletedCreativeSetReport(key);
          this.disabledCreativeSetReport(key);
          this.creativesetSearchFilter(key);
          break;

        case 6:
          this.searchTermsSearchFilter(key);
          break;

        case 7:
          this.deletedNegKeywordReport(key);
          this.disabledNegKeywordReport(key);
          this.negKeywordSearchFilter(key);
          break;
      }
      this.drawer = false;
    },

    accountsSearchFilter(key) {
      this.filteredAccountReports = this.accountsReport;

      let tempAccountsReports = [];
      for (
        let index = 0;
        index < this.filteredAccountReports.row.length;
        index++
      ) {
        const element = this.filteredAccountReports.row[index];

        if (key != '') {
          if (
            element.orgMetadata.orgName
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempAccountsReports.push(element);
          }
        } else {
          tempAccountsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredAccountReports.grandTotals,
        row: tempAccountsReports,
      };
      this.filteredAccountReports = reportsSchema;
    },

    appsSearchFilter(key) {
      this.filteredAppReports = this.appsReport;

      let tempAppsReports = [];
      for (let index = 0; index < this.filteredAppReports.row.length; index++) {
        const element = this.filteredAppReports.row[index];

        if (key != '') {
          if (
            element.metadata.app.appName
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempAppsReports.push(element);
          }
        } else {
          tempAppsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredAppReports.grandTotals,
        row: tempAppsReports,
      };
      this.filteredAppReports = reportsSchema;
    },

    adgroupSearchFilter(key) {
      this.filteredAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.filteredAdgroupReports.row) {
        if (
          key != '' &&
          element.adgroupMetadata.status == 'ENABLED' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.adgroupMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempAdgroupReports.push(element);
          }
        } else if (
          element.adgroupMetadata.status == 'ENABLED' &&
          !element.campaignMetadata.deleted
        ) {
          tempAdgroupReports.push(element);
        }
      }
      if (tempAdgroupReports.length != 0 && key == '') {
        this.adgroupListingTab = 0;
      }
      let reportsSchema = {
        grandTotals: this.filteredAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.filteredAdgroupReportsCount = tempAdgroupReports.length;
      this.filteredAdgroupReports = reportsSchema;
    },

    keywordSearchFilter(key) {
      this.filteredKeywordReports = this.keywordReport;

      let tempKeywordReports = [];
      for (const element of this.filteredKeywordReports.row) {
        if (
          key != '' &&
          element.keywordMetadata.status == 'ACTIVE' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.keywordMetadata.text
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempKeywordReports.push(element);
          }
        } else if (
          element.keywordMetadata.status == 'ACTIVE' &&
          !element.campaignMetadata.deleted
        ) {
          tempKeywordReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredKeywordReports.grandTotals,
        row: tempKeywordReports,
      };
      if (tempKeywordReports.length != 0 && key == '') {
        this.keywordListingTab = 0;
      }
      this.filteredKeywordReportsCount = tempKeywordReports.length;
      this.filteredKeywordReports = reportsSchema;
    },

    creativesetSearchFilter(key) {
      this.filteredCreativeSetReports = this.creativeSetReport;

      let tempCreativeSetReports = [];
      for (const element of this.filteredCreativeSetReports.row) {
        if (
          key != '' &&
          element.creativeSetMetadata.status == 'VALID' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.creativeSetMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCreativeSetReports.push(element);
          }
        } else if (
          element.creativeSetMetadata.status == 'VALID' &&
          !element.campaignMetadata.deleted
        ) {
          tempCreativeSetReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      if (tempCreativeSetReports.length != 0 && key == '') {
        this.creativeListingTab = 0;
      }
      this.filteredCreativeSetReportsCount = tempCreativeSetReports.length;
      this.filteredCreativeSetReports = reportsSchema;
    },

    searchTermsSearchFilter(key) {
      this.filteredSearchTermsReports = this.searchTermsReport;

      let tempSearchTermsReports = [];
      for (
        let index = 0;
        index < this.filteredSearchTermsReports.row.length;
        index++
      ) {
        const element = this.filteredSearchTermsReports.row[index];

        if (key != '') {
          if (
            element.creativeSetMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempSearchTermsReports.push(element);
          }
        } else {
          tempSearchTermsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredSearchTermsReports.grandTotals,
        row: tempSearchTermsReports,
      };
      this.filteredSearchTermsReports = reportsSchema;
    },

    negKeywordSearchFilter(key) {
      this.filteredNegKeywordReports = this.negKeywordReport;

      let tempNegKeyReports = [];
      for (const element of this.filteredNegKeywordReports) {
        if (
          key != '' &&
          element.status == 'ACTIVE' &&
          element.deleted == false
        ) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.status == 'ACTIVE' && element.deleted == false) {
          tempNegKeyReports.push(element);
        }
      }
      if (tempNegKeyReports.length != 0 && key == '') {
        this.negKeyListingTab = 0;
      }
      this.filteredNegKeywordReportsCount = tempNegKeyReports.length;
      this.filteredNegKeywordReports = tempNegKeyReports;
    },

    liveCampaignReport(key) {
      this.liveCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.liveCampaignReports.row) {
        if (
          key != '' &&
          element.campaignMetadata.status == 'ENABLED' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.campaignMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCampaignReports.push(element);
          }
        } else if (
          element.campaignMetadata.status == 'ENABLED' &&
          !element.campaignMetadata.deleted
        ) {
          tempCampaignReports.push(element);
        }
      }
      if (tempCampaignReports.length != 0 && key == '') {
        this.campaignListingTab = 0;
      }
      let campaignReportsSchema = {
        grandTotals: this.liveCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.liveReportsCount = tempCampaignReports.length;
      this.liveCampaignReports = campaignReportsSchema;
    },

    disabledCampaignReport(key) {
      this.disabledCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.disabledCampaignReports.row) {
        if (
          key != '' &&
          element.campaignMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.campaignMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCampaignReports.push(element);
          }
        } else if (
          element.campaignMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          tempCampaignReports.push(element);
        }
      }
      if (tempCampaignReports.length != 0 && key == '') {
        this.campaignListingTab = 1;
      }
      let campaignReportsSchema = {
        grandTotals: this.disabledCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.disabledReportsCount = tempCampaignReports.length;
      this.disabledCampaignReports = campaignReportsSchema;
    },

    deletedCampaignReport(key) {
      this.deletedCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.deletedCampaignReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (
            element.campaignMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCampaignReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempCampaignReports.push(element);
        }
      }
      if (tempCampaignReports.length != 0 && key == '') {
        this.campaignListingTab = 2;
      }
      let campaignReportsSchema = {
        grandTotals: this.deletedCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.deletedReportsCount = tempCampaignReports.length;
      this.deletedCampaignReports = campaignReportsSchema;
    },

    disabledAdgroupReport(key) {
      this.disabledAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.disabledAdgroupReports.row) {
        if (
          key != '' &&
          element.adgroupMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.adgroupMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempAdgroupReports.push(element);
          }
        } else if (
          element.adgroupMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          tempAdgroupReports.push(element);
        }
      }
      if (tempAdgroupReports.length != 0 && key == '') {
        this.adgroupListingTab = 1;
      }
      let reportsSchema = {
        grandTotals: this.disabledAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.disabledAdgroupReportsCount = tempAdgroupReports.length;
      this.disabledAdgroupReports = reportsSchema;
    },

    deletedAdgroupReport(key) {
      this.deletedAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.deletedAdgroupReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (
            element.adgroupMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempAdgroupReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempAdgroupReports.push(element);
        }
      }
      if (tempAdgroupReports.length != 0 && key == '') {
        this.adgroupListingTab = 2;
      }
      let reportsSchema = {
        grandTotals: this.deletedAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.deletedAdgroupReportsCount = tempAdgroupReports.length;
      this.deletedAdgroupReports = reportsSchema;
    },

    disabledKeywordReport(key) {
      this.disabledKeywordReports = this.keywordReport;
      let tempKeywordReports = [];
      for (const element of this.disabledKeywordReports.row) {
        if (
          key != '' &&
          element.keywordMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.keywordMetadata.text
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempKeywordReports.push(element);
          }
        } else if (
          element.keywordMetadata.status == 'PAUSED' &&
          !element.campaignMetadata.deleted
        ) {
          tempKeywordReports.push(element);
        }
      }
      if (tempKeywordReports.length != 0 && key == '') {
        this.keywordListingTab = 1;
      }
      let reportsSchema = {
        grandTotals: this.disabledKeywordReports.grandTotals,
        row: tempKeywordReports,
      };
      this.disabledKeywordReportsCount = tempKeywordReports.length;
      this.disabledKeywordReports = reportsSchema;
    },

    deletedKeywordReport(key) {
      this.deletedKeywordReports = this.keywordReport;
      let tempKeywordReports = [];
      for (const element of this.deletedKeywordReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (
            element.keywordMetadata.text
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempKeywordReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempKeywordReports.push(element);
        }
      }
      if (tempKeywordReports.length != 0 && key == '') {
        this.keywordListingTab = 2;
      }
      let reportsSchema = {
        grandTotals: this.deletedKeywordReports.grandTotals,
        row: tempKeywordReports,
      };
      this.deletedKeywordReportsCount = tempKeywordReports.length;
      this.deletedKeywordReports = reportsSchema;
    },

    disabledCreativeSetReport(key) {
      this.disabledCreativeSetReports = this.creativeSetReport;
      let tempCreativeSetReports = [];
      for (const element of this.disabledCreativeSetReports.row) {
        if (
          key != '' &&
          element.creativeSetMetadata.status == 'INVALID' &&
          !element.campaignMetadata.deleted
        ) {
          if (
            element.creativeSetMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCreativeSetReports.push(element);
          }
        } else if (
          element.creativeSetMetadata.status == 'INVALID' &&
          !element.campaignMetadata.deleted
        ) {
          tempCreativeSetReports.push(element);
        }
      }
      if (tempCreativeSetReports.length != 0 && key == '') {
        this.creativeListingTab = 1;
      }
      let reportsSchema = {
        grandTotals: this.disabledCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      this.disabledCreativeSetReportsCount = tempCreativeSetReports.length;
      this.disabledCreativeSetReports = reportsSchema;
    },

    deletedCreativeSetReport(key) {
      this.deletedCreativeSetReports = this.creativeSetReport;
      let tempCreativeSetReports = [];
      for (const element of this.deletedCreativeSetReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (
            element.creativeSetMetadata.name
              .toLowerCase()
              .includes(key.toLowerCase())
          ) {
            tempCreativeSetReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempCreativeSetReports.push(element);
        }
      }
      if (tempCreativeSetReports.length != 0 && key == '') {
        this.creativeListingTab = 2;
      }
      let reportsSchema = {
        grandTotals: this.deletedCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      this.deletedCreativeSetReportsCount = tempCreativeSetReports.length;
      this.deletedCreativeSetReports = reportsSchema;
    },

    disabledNegKeywordReport(key) {
      this.disabledNegKeywordReports = this.negKeywordReport;
      let tempNegKeyReports = [];
      for (const element of this.disabledNegKeywordReports) {
        if (
          key != '' &&
          element.status == 'PAUSED' &&
          element.deleted == false
        ) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.status == 'PAUSED' && element.deleted == false) {
          tempNegKeyReports.push(element);
        }
      }
      if (tempNegKeyReports.length != 0 && key == '') {
        this.negKeyListingTab = 1;
      }
      this.disabledNegKeywordReportsCount = tempNegKeyReports.length;
      this.disabledNegKeywordReports = tempNegKeyReports;
    },

    deletedNegKeywordReport(key) {
      this.deletedNegKeywordReports = this.negKeywordReport;
      let tempNegKeyReports = [];
      for (const element of this.deletedNegKeywordReports) {
        if (key != '' && element.deleted == true) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.deleted == true) {
          tempNegKeyReports.push(element);
        }
      }
      if (tempNegKeyReports.length != 0 && key == '') {
        this.negKeyListingTab = 2;
      }
      this.deletedNegKeywordReportsCount = tempNegKeyReports.length;
      this.deletedNegKeywordReports = tempNegKeyReports;
    },

    async refetchData() {
      switch (this.currentTab) {
        case AdsManagerTabs.ACCOUNTS: {
          this.$apollo.queries.accountsGraph.refetch();
          this.$apollo.queries.accountsReports.refetch();
          break;
        }
        case AdsManagerTabs.APPS: {
          this.$apollo.queries.appsChart.refetch();
          this.$apollo.queries.appsReports.refetch();
          break;
        }
        case AdsManagerTabs.CAMPAIGNS: {
          this.$apollo.queries.campaignChart.refetch();
          this.$apollo.queries.campaignReports.refetch();
          break;
        }
        case AdsManagerTabs.AD_GROUPS: {
          this.$apollo.queries.adGroupGraph.refetch();
          this.$apollo.queries.adGroupReports.refetch();
          break;
        }
        case AdsManagerTabs.KEYWORDS: {
          this.$apollo.queries.keywordsGraph.refetch();
          this.$apollo.queries.targetingKeywordReports.refetch();
          break;
        }
        case AdsManagerTabs.CREATIVE_SETS: {
          this.$apollo.queries.creativeSetGraph.refetch();
          this.$apollo.queries.creativeSetReports.refetch();
          break;
        }
        case AdsManagerTabs.SEARCH_TERMS: {
          this.$apollo.queries.searchTermsGraph.refetch();
          this.$apollo.queries.searchTermsReports.refetch();
          break;
        }
        case AdsManagerTabs.NEG_KEYWORDS: {
          this.$apollo.queries.negativeKeywordReports.refetch();
          break;
        }
      }
    },
  },
  mounted() {
    const tab = this.$route.query.tab ? this.$route.query.tab : '';
    if (tab == 'keywords') {
      this.tab = 4;
      //this.currentTab = AdsManagerTabs.KEYWORDS;
    } else if (tab == 'adgroups') {
      this.tab = 3;
    } else if (tab == 'campaigns') {
      this.tab = 2;
    } else {
    }
    updateCLF('adsManager', this);
  },
  created() {
    bus.$on(BusEvents.DATA_PULLED, () => {
      this.refetchData();
    });
  },
});
