




















































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as _ from 'lodash';
import Vue from 'vue';
import {
  OWN_APPS,
  KEYWORD_RECOMENDS,
  APP_METADATA,
  SEARCH_FOR_APPS,
} from '../../utils/apptweakqueries';
import { CampaignsService } from '../../services/campaigns.service';
import { LSS } from '@core/services';
import { MaxCPTBidOptions } from '../../utils/enums';
import { ADD_KEYWORDS } from 'src/utils/apollo-mutations';
import { updateCLF } from '../../utils/util'

export default Vue.extend({
  name: 'AddKeywords',
  data() {
    return {
      valid: false,
      msgModel: false,
      message: '',
      msgTitle: '',
      amountRules: [
        (v) => !!v || 'Bid amount is required',
        (v) =>
          (v && v != 0 && v > 0) ||
          'Bid amount must be numeric and more than 0',
      ],
      keyRules: [
        (v) =>
          !this.keywords.find((kw) => kw.text === v) || 'Keyword already added',
      ],
      saving: false,
      searchApp: null,
      loadingApps: false,
      searchedApps: [],
      step: 1,
      first: '',
      searchedKeyword: '',
      recomendHeaders: [
        {
          text: 'Keyword',
          align: 'start',
          value: 'keyword',
        },
        { text: 'Popularity', value: 'volume' },
        {
          text: '',
          value: 'addKeywordPlus',
        },
      ],
      recomendItems: [],
      loadingRecomends: false,
      selectedCountry: {
        name: 'United States',
        code: 'us',
      },
      countries: [
        {
          name: 'United States',
          code: 'us',
        },
        {
          name: 'India',
          code: 'in',
        },
      ],
      selectedAppName: '',
      selectedAppIcon: '',
      selectedAppDev: '',

      matchTypes: ['EXACT', 'BROAD'],
      maxCptBidOptions: [
        { text: 'Ad Group Default', value: MaxCPTBidOptions.ADGROUP_DEFAULT },
        { text: 'Custom', value: MaxCPTBidOptions.CUSTOM },
      ],
      maxCptBid: MaxCPTBidOptions.CUSTOM,

      show: false,
      keywordInput: '',
      keywords: [],
      currency: '',

      selectedAdgroups: [],
      totalCampaigns: 0,
      totalAdgroups: 0,

      searchAppId: 0,
      prevSearchAppId: 0,
      loading: false,
      search: '',
      fullscreenLoader: null,
    };
  },
  props: {
    adgroupDetails: {
      type: Object,
      required: false,
    },
  },
  watch: {
    adgroupDetails: function (data) {
      console.log(data);
      this.show = true;
      if (data.selectedApp) {
        this.searchApp = data.selectedApp
        this.changeApp(this.searchApp);
      }
      this.selectedAdgroups = data?.selectedAdgroups;
      // using same currency as in adgroup settings
      this.currency = this.selectedAdgroups[0]?.defaultBidAmount.currency;

      this.totalAdgroups = this.selectedAdgroups.length;
      this.totalCampaigns = _.uniq(
        this.selectedAdgroups.map((group) => group.campaignId)
      ).length;
    },
  },
  methods: {
    close() {
      this.show = false;
      this.keywordInput = '';
      this.keywords = [];
    },
    addKeyword(): void {
      const keys = this.keywordInput.split(',');
      //console.log(this.keywords);
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (element && !this.keywords.find((kw) => kw.text === element)) {
          //console.log('in ',this.keywords);
          this.keywords.push({
            text: element,
            matchType: 'EXACT',
            bidAmount: { currency: this.currency, amount: '0.1' },
          });
        }
        this.keywordInput = '';
      }
    },
    deleteKeyword(keyword: string): void {
      this.keywords = this.keywords.filter((kw) => kw.text !== keyword);
    },
    async save() {
      this.$refs.form.validate();
      for (let index = 0; index < this.keywords.length; index++) {
        const element = this.keywords[index];
        if (
          element.bidAmount.amount == 0 ||
          element.bidAmount.amount == '' ||
          element.bidAmount.amount < 0
        ) {
          this.msgModel = true;
          this.msgTitle = 'Info!!!';
          this.message = 'Bid amount must be greater than 0';
          return;
        }
      }
      this.saving = true;
      const addKeywordsReq = {
        keywords: this.keywords,
        adgroups: this.selectedAdgroups,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_KEYWORDS,
          variables: {
            addKeywords: addKeywordsReq,
          },
        });

        if (result.data.addKeywords.searchAdsErrors) {
          this.$notify.error(result.data.addKeywords.searchAdsErrors);
        } else {
          this.$notify.success('Keywords added.');
          updateCLF('addKeys', this)
          this.close();
        }
      } catch {}

      this.saving = false;
    },
    change() {
      this.$emit('onClickChange', this.selectedAdgroups, this.keysFromKai);
      this.close();
    },
    getOwnApps() {
      this.loading = true;
      this.$apollo
        .query({
          query: OWN_APPS,
          variables: {
            from: 'AddKeywords',
          },
        })
        .then((res) => {
          this.searchedApps = res.data.getAllOwnApps;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    changeApp(item) {
      this.fullscreenLoader = this.$loading.show();
      this.recomendItems = [];
      this.selectedAppIcon = '';
      this.selectedAppDev = '';

      this.selectedAppName = item.appName;
      this.loadingRecomends = true;
      this.$apollo
        .query({
          query: KEYWORD_RECOMENDS,
          variables: {
            appid: item.adamId,
            country: this.selectedCountry.code,
            term: '',
          },
        })
        .then((res) => {
          this.loadingRecomends = false;
          this.recomendItems = res.data.KeywordRecomends.content
            ? res.data.KeywordRecomends.content
            : [];
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          this.loadingRecomends = false;
          this.fullscreenLoader.hide();
        });

      this.$apollo
        .query({
          query: APP_METADATA,
          variables: {
            appId: item.adamId,
            location: this.selectedCountry.code,
            language: 'en',
            device: 'iphone',
            startDate: new Date().toISOString().substr(0, 10),
            endDate: new Date().toISOString().substr(0, 10),
          },
        })
        .then((data) => {
          if (
            data.data.getAppMetadata == null ||
            data.data.getAppMetadata == undefined
          ) {
            this.loadingKeys = false;
            this.noDataTextKeys = 'App details not found';
            return;
          }
          this.selectedAppIcon = data.data.getAppMetadata.content.icon;
          this.selectedAppDev = data.data.getAppMetadata.content.developer.name;
        })
        .catch((error) => {
          this.loadingKeys = false;
          this.noDataTextKeys = 'App details not found';
        });
    },
    addKeywordPlusClicked(item) {
      if (
        item.keyword &&
        !this.keywords.find((kw) => kw.text === item.keyword)
      ) {
        this.keywords.push({
          text: item.keyword,
          matchType: 'EXACT',
          bidAmount: { currency: this.currency, amount: '0.1' },
        });
      }
    },
    countryChange(item) {
      this.selectedCountry = item;
      this.changeApp(this.searchAppId);
    },
  },
  mounted() {
    this.getOwnApps();
  },
});
