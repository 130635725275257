var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-5" },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-space-between pa-0 mb-0" },
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("editAdgroups.edit")))]),
              _c(
                "v-icon",
                {
                  attrs: { disabled: _vm.savingUpdates },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              [
                _c(
                  "v-card",
                  { attrs: { elevation: "0" } },
                  [
                    _c("v-toolbar", {
                      attrs: { flat: "", prominent: "", height: "30" },
                      scopedSlots: _vm._u([
                        {
                          key: "extension",
                          fn: function() {
                            return [
                              _c(
                                "v-tabs",
                                {
                                  attrs: {
                                    "background-color": "transparent",
                                    color: "success accent-4",
                                    right: ""
                                  },
                                  model: {
                                    value: _vm.tabs,
                                    callback: function($$v) {
                                      _vm.tabs = $$v
                                    },
                                    expression: "tabs"
                                  }
                                },
                                _vm._l(_vm.items, function(item) {
                                  return _c("v-tab", { key: item }, [
                                    _vm._v(_vm._s(item))
                                  ])
                                }),
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tabs,
                          callback: function($$v) {
                            _vm.tabs = $$v
                          },
                          expression: "tabs"
                        }
                      },
                      [
                        _c(
                          "v-tab-item",
                          [
                            _c(
                              "v-card",
                              { attrs: { flat: "" } },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0" },
                                  [
                                    _c(
                                      "v-form",
                                      {
                                        staticClass: "adgroupform",
                                        model: {
                                          value: _vm.valid,
                                          callback: function($$v) {
                                            _vm.valid = $$v
                                          },
                                          expression: "valid"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "logo d-flex align-center justify-space-between mb-5"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "left-content d-flex align-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "logo-content"
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "app-name mb-0"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addAdgroup.adgroupSett"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "right-content text-right"
                                              },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "secondary--text"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "success"
                                                        }
                                                      },
                                                      [_vm._v("mdi-circle")]
                                                    ),
                                                    _vm._v("Running ")
                                                  ],
                                                  1
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "editAdgroups.adgroup"
                                                        )
                                                      ) +
                                                      ": "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-light"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          this.adgroupDetails
                                                            .name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" }
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "black--text mb-2 d-flex"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "addAdgroup.adgroupName"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "sup",
                                                      {
                                                        staticClass: "red--text"
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Ad Group Name",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                    rules: _vm.nameRules
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.adgroupDetails.name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.adgroupDetails,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "adgroupDetails.name"
                                                  }
                                                }),
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "mt-5",
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pt-0",
                                                        attrs: { cols: "6" }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "field"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "black--text mb-2 d-flex"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "addAdgroup.defaultMax"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "sup",
                                                                  {
                                                                    staticClass:
                                                                      "red--text"
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                      "max-width":
                                                                        "600"
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-help-circle"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ]
                                                                    )
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "This is the maximum amount you're willing to pay for a tap on your add.This bid will apply to all keywords in this ad group unless you apply an individual keyword bid."
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                required: "",
                                                                outlined: "",
                                                                dense: "",
                                                                "prepend-inner-icon":
                                                                  "mdi-currency-usd",
                                                                rules:
                                                                  _vm.amountRules
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .adgroupDetails
                                                                    .defaultBidAmount
                                                                    .amount,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .adgroupDetails
                                                                      .defaultBidAmount,
                                                                    "amount",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                                  adgroupDetails.defaultBidAmount.amount\n                                "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pt-0",
                                                        attrs: { cols: "6" }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "field"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "black--text mb-2 d-flex"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "addAdgroup.cpaGoal"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                required: "",
                                                                outlined: "",
                                                                dense: "",
                                                                "prepend-inner-icon":
                                                                  "mdi-currency-usd"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.cpaGoalAmount,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.cpaGoalAmount = $$v
                                                                },
                                                                expression:
                                                                  "cpaGoalAmount"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "6" }
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "mb-2 d-flex"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "addAdgroup.searchMatch"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-help-circle"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "pa-4 d-flex align-center",
                                                    attrs: { outlined: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "my-2 mr-4"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addAdgroup.searchPara"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-switch", {
                                                      attrs: { inset: "" },
                                                      model: {
                                                        value:
                                                          _vm.adgroupDetails
                                                            .automatedKeywordsOptIn,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.adgroupDetails,
                                                            "automatedKeywordsOptIn",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "adgroupDetails.automatedKeywordsOptIn"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" }
                                              },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "editAdgroups.advance"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "12" }
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "mb-5 pa-4 d-flex align-center justify-center",
                                                    attrs: { outlined: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-dialog",
                                                      {
                                                        attrs: {
                                                          "max-width": "1000px"
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "my-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "success",
                                                                              text:
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs: {
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-pencil"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "editAdgroups.viewEdit"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ]),
                                                        model: {
                                                          value: _vm.dialog2,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.dialog2 = $$v
                                                          },
                                                          expression: "dialog2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass: "pa-5"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between pa-0 mb-0"
                                                              },
                                                              [
                                                                _c("h4", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "editAdgroups.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.close()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-card-text",
                                                              {
                                                                staticClass:
                                                                  "pa-0"
                                                              },
                                                              [
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "mt-5",
                                                                      attrs: {
                                                                        outlined:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-toolbar",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            height:
                                                                              "10"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-tabs",
                                                                        {
                                                                          attrs: {
                                                                            vertical:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "h4",
                                                                            {
                                                                              staticClass:
                                                                                "ml-4 mb-2 font-weight-medium"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "editAdgroups.general"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-cog"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "editAdgroups.settings"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-crosshairs"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "addAdgroup.searchMatch"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "h4",
                                                                            {
                                                                              staticClass:
                                                                                "ml-4 mb-2 font-weight-medium"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "editAdgroups.schedule"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-calendar"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "addAdgroup.adScheduling"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "h4",
                                                                            {
                                                                              staticClass:
                                                                                "ml-4 mb-2 font-weight-medium"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "createCampaign.audience"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-cellphone"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.devices"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-account-group"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.custType"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-gender-transgender"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.demo"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            {
                                                                              staticClass:
                                                                                "justify-start"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    left:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-map-marker"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.location"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-center mb-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "addAdgroup.adgroupName"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "sup",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "red--text"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "*"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-text-field",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    label:
                                                                                                      "Ad Group Name",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    dense:
                                                                                                      "",
                                                                                                    required:
                                                                                                      "",
                                                                                                    rules:
                                                                                                      _vm.nameRules
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .adgroupDetails
                                                                                                        .name,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.adgroupDetails,
                                                                                                        "name",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                    expression:
                                                                                                      "\n                                                    adgroupDetails.name\n                                                  "
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-row",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-5",
                                                                                          attrs: {
                                                                                            align:
                                                                                              "center"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-0",
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "6"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "field"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "label",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "black--text mb-3 d-flex"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "addAdgroup.defaultMax"
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            small:
                                                                                                              ""
                                                                                                          }
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-help-circle"
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-text-field",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        required:
                                                                                                          "",
                                                                                                        outlined:
                                                                                                          "",
                                                                                                        dense:
                                                                                                          "",
                                                                                                        "prepend-inner-icon":
                                                                                                          "mdi-currency-usd",
                                                                                                        rules:
                                                                                                          _vm.amountRules
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .adgroupDetails
                                                                                                            .defaultBidAmount
                                                                                                            .amount,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            _vm
                                                                                                              .adgroupDetails
                                                                                                              .defaultBidAmount,
                                                                                                            "amount",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                        expression:
                                                                                                          "\n                                                      adgroupDetails\n                                                        .defaultBidAmount\n                                                        .amount\n                                                    "
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-0",
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "6"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "field"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "label",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "black--text mb-3 d-flex"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "addAdgroup.cpaGoal"
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            small:
                                                                                                              ""
                                                                                                          }
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-help-circle"
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-text-field",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        required:
                                                                                                          "",
                                                                                                        outlined:
                                                                                                          "",
                                                                                                        dense:
                                                                                                          "",
                                                                                                        "prepend-inner-icon":
                                                                                                          "mdi-currency-usd"
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm.cpaGoalAmount,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.cpaGoalAmount = $$v
                                                                                                        },
                                                                                                        expression:
                                                                                                          "cpaGoalAmount"
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mb-2 d-flex"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "addAdgroup.searchMatch"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "ml-2",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-help-circle"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-card",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "pa-4 d-flex align-center justify-space-between",
                                                                                                  attrs: {
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "p",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "my-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "addAdgroup.searchPara"
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-switch",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        inset:
                                                                                                          ""
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .adgroupDetails
                                                                                                            .automatedKeywordsOptIn,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            _vm.adgroupDetails,
                                                                                                            "automatedKeywordsOptIn",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                        expression:
                                                                                                          "\n                                                      adgroupDetails.automatedKeywordsOptIn\n                                                    "
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        {
                                                                                          attrs: {
                                                                                            align:
                                                                                              "center"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "6"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "field mt-16"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "label",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "black--text mb-0 d-flex"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "addAdgroup.startTime"
                                                                                                          )
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-datetime-picker",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mt-0 pt-0",
                                                                                                      attrs: {
                                                                                                        disabled: true,
                                                                                                        datePickerProps:
                                                                                                          _vm.datePickerProps
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm.selectedStartTime,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.selectedStartTime = $$v
                                                                                                        },
                                                                                                        expression:
                                                                                                          "\n                                                      selectedStartTime\n                                                    "
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "6"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "field"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-checkbox",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        label:
                                                                                                          "End Time (Optional)",
                                                                                                        color:
                                                                                                          "primary",
                                                                                                        value:
                                                                                                          "End Time",
                                                                                                        "hide-details":
                                                                                                          ""
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm.checkbox,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.checkbox = $$v
                                                                                                        },
                                                                                                        expression:
                                                                                                          "checkbox"
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "field mt-5"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "label",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "black--text mb-0 d-flex"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "addAdgroup.endTime"
                                                                                                          )
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-datetime-picker",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mt-0 pt-0",
                                                                                                      attrs: {
                                                                                                        outlined:
                                                                                                          "",
                                                                                                        datePickerProps:
                                                                                                          _vm.datePickerProps
                                                                                                      },
                                                                                                      model: {
                                                                                                        value:
                                                                                                          _vm.selectedEndTime,
                                                                                                        callback: function(
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.selectedEndTime = $$v
                                                                                                        },
                                                                                                        expression:
                                                                                                          "selectedEndTime"
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex mb-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.devices"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-select",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    placeholder:
                                                                                                      "Select Devices",
                                                                                                    items:
                                                                                                      _vm.Devices,
                                                                                                    required:
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    multiple:
                                                                                                      "",
                                                                                                    dense:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm.selectedDeviceClass,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.selectedDeviceClass = $$v
                                                                                                    },
                                                                                                    expression:
                                                                                                      "\n                                                    selectedDeviceClass\n                                                  "
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex mb-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.custType"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-select",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    placeholder:
                                                                                                      "All",
                                                                                                    items:
                                                                                                      _vm.customerTypes,
                                                                                                    required:
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    dense:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm.selectedCustomerType,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.selectedCustomerType = $$v
                                                                                                    },
                                                                                                    expression:
                                                                                                      "\n                                                    selectedCustomerType\n                                                  "
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "editAdgroups.custPara"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mb-2 d-flex"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "addAdgroup.ageRange"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-card",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "pa-4 d-flex align-center justify-center flex-column",
                                                                                                  attrs: {
                                                                                                    outlined:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "age-select d-flex align-center justify-center"
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "p",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "my-2 mr-2"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "editAdgroups.selectAge"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-switch",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            inset:
                                                                                                              ""
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm.ageRange,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.ageRange = $$v
                                                                                                            },
                                                                                                            expression:
                                                                                                              "ageRange"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "range"
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-range-slider",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "thumb-label": true,
                                                                                                            disabled: !_vm.ageRange,
                                                                                                            step:
                                                                                                              "1",
                                                                                                            min:
                                                                                                              "18",
                                                                                                            max:
                                                                                                              "65",
                                                                                                            "background-color":
                                                                                                              "light",
                                                                                                            "track-color":
                                                                                                              "grey",
                                                                                                            "track-fill-color":
                                                                                                              "sucess",
                                                                                                            "thumb-color":
                                                                                                              "sucess"
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              _vm.ageRangeValue,
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.ageRangeValue = $$v
                                                                                                            },
                                                                                                            expression:
                                                                                                              "ageRangeValue"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-row",
                                                                                        [
                                                                                          _c(
                                                                                            "v-col",
                                                                                            {
                                                                                              attrs: {
                                                                                                cols:
                                                                                                  "12",
                                                                                                sm:
                                                                                                  "12"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex mb-2"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.gender"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-select",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    placeholder:
                                                                                                      "Select gender",
                                                                                                    items:
                                                                                                      _vm.gender,
                                                                                                    required:
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    dense:
                                                                                                      ""
                                                                                                  },
                                                                                                  model: {
                                                                                                    value:
                                                                                                      _vm.selectedGender,
                                                                                                    callback: function(
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.selectedGender = $$v
                                                                                                    },
                                                                                                    expression:
                                                                                                      "selectedGender"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "v-card",
                                                                                {
                                                                                  attrs: {
                                                                                    flat:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "field"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-autocomplete",
                                                                                            {
                                                                                              attrs: {
                                                                                                items:
                                                                                                  _vm.locations,
                                                                                                "item-text":
                                                                                                  "displayName",
                                                                                                "item-value":
                                                                                                  "id",
                                                                                                label:
                                                                                                  "Locations",
                                                                                                required:
                                                                                                  "",
                                                                                                outlined:
                                                                                                  "",
                                                                                                dense:
                                                                                                  "",
                                                                                                multiple:
                                                                                                  "",
                                                                                                "hide-no-data":
                                                                                                  "",
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                loading:
                                                                                                  _vm.loading,
                                                                                                "search-input":
                                                                                                  _vm.searchLoc,
                                                                                                "cache-items":
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                "update:searchInput": function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.searchLoc = $event
                                                                                                },
                                                                                                "update:search-input": function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.searchLoc = $event
                                                                                                }
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.selectedLocalities,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.selectedLocalities = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "selectedLocalities"
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              ],
                                                              2
                                                            ),
                                                            _c(
                                                              "v-card-actions",
                                                              {
                                                                staticClass:
                                                                  "justify-end mt-7 pa-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        "127",
                                                                      color:
                                                                        "secondary",
                                                                      outlined:
                                                                        "",
                                                                      depressed:
                                                                        "",
                                                                      large: "",
                                                                      disabled:
                                                                        _vm.savingUpdates
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.close()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createCampaign.cancel"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        "114",
                                                                      color:
                                                                        "success",
                                                                      depressed:
                                                                        "",
                                                                      large: "",
                                                                      disabled:
                                                                        !_vm.valid ||
                                                                        _vm.savingUpdates,
                                                                      loading:
                                                                        _vm.savingUpdates
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateAdgroup()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "addAdgroup.apply"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-0" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "secondary",
                    outlined: "",
                    depressed: "",
                    large: "",
                    disabled: _vm.savingUpdates
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("createCampaign.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "114",
                    color: "success",
                    depressed: "",
                    large: "",
                    disabled: !_vm.valid || _vm.savingUpdates,
                    loading: _vm.savingUpdates
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateAdgroup()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("editAdgroups.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }