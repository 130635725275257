var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "v-fixed-right",
              attrs: { fixed: "", temporary: "", right: "", width: "450" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "v-filter-header" },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "w-100",
                          attrs: {
                            "d-flex": "",
                            "align-center": "",
                            "justify-space-between": ""
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("buttons.filter")))
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "black--text v-close-ic",
                              attrs: { left: "" },
                              on: {
                                click: function($event) {
                                  _vm.drawer = false
                                }
                              }
                            },
                            [_vm._v("close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-form",
                            [
                              _c(
                                "div",
                                { staticClass: "mt-2 v-search-box" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "no-field-bottom-space" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Search",
                                          outlined: "",
                                          dense: "",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.keyFilter,
                                          callback: function($$v) {
                                            _vm.keyFilter = $$v
                                          },
                                          expression: "keyFilter"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "rounded-lg mr-0 v-search-icon-btn",
                                      attrs: { depressed: "", medium: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.applySearchFilter()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            medium: "",
                                            color: "primary"
                                          }
                                        },
                                        [_vm._v("mdi-magnify")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.tab == 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg",
                                          attrs: {
                                            depressed: "",
                                            dense: "",
                                            medium: ""
                                          },
                                          on: { click: _vm.resetFilters }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("buttons.reset")) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.ACCOUNT
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.account")))
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items:
                                          (_vm.accountsReportsForFilter &&
                                            _vm.accountsReportsForFilter.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "orgMetadata.orgName",
                                        "item-value": "orgMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .accountsReportsForFilter.loading
                                      },
                                      model: {
                                        value: _vm.selectedAccount,
                                        callback: function($$v) {
                                          _vm.selectedAccount = $$v
                                        },
                                        expression: "selectedAccount"
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(_vm.adsManagerFilters.APPS)
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.app")))
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items:
                                          (_vm.appsReportsForFilter &&
                                            _vm.appsReportsForFilter.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "metadata.app.appName",
                                        "item-value": "metadata.app",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .appsReportsForFilter.loading
                                      },
                                      model: {
                                        value: _vm.selectedApp,
                                        callback: function($$v) {
                                          _vm.selectedApp = $$v
                                        },
                                        expression: "selectedApp"
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.CAMPAIGN
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.campaign")))
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items:
                                          (_vm.campaignReportsForFilter &&
                                            _vm.campaignReportsForFilter.row) ||
                                          [],
                                        placeholder: "Select",
                                        "item-text": "campaignMetadata.name",
                                        "item-value": "campaignMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .campaignReportsForFilter.loading
                                      },
                                      model: {
                                        value: _vm.selectedCampaign,
                                        callback: function($$v) {
                                          _vm.selectedCampaign = $$v
                                        },
                                        expression: "selectedCampaign"
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _vm.shouldFilterAppear(
                                _vm.adsManagerFilters.ADGROUP
                              )
                                ? [
                                    _c("v-label", { attrs: { block: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("filter.adgroup")))
                                    ]),
                                    _c("v-select", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        items:
                                          _vm.adgroupReportsForFilter &&
                                          _vm.adgroupReportsForFilter.row,
                                        placeholder: "Select",
                                        "item-text": "adgroupMetadata.name",
                                        "item-value": "adgroupMetadata",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                        loading:
                                          _vm.$apollo.queries
                                            .adgroupReportsForFilter.loading
                                      },
                                      model: {
                                        value: _vm.selectedAdGroup,
                                        callback: function($$v) {
                                          _vm.selectedAdGroup = $$v
                                        },
                                        expression: "selectedAdGroup"
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _vm.tab != 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg mr-3",
                                          attrs: {
                                            color: "primary",
                                            depressed: "",
                                            dense: "",
                                            medium: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.runFilterQueries()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("buttons.apply"))
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.tab != 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "rounded-lg",
                                          attrs: {
                                            depressed: "",
                                            dense: "",
                                            medium: ""
                                          },
                                          on: { click: _vm.resetFilters }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("buttons.reset"))
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inner-container" },
            [
              _c(
                "div",
                { staticClass: "top-page-header" },
                [
                  _c("div", { staticClass: "head-title withBreadcrumb" }, [
                    _c("div", { staticClass: "top-heading-left" }, [
                      _c("h1", [
                        _vm._v(" " + _vm._s(_vm.$t("pages.adsManager")) + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "breadcrumb" },
                        [
                          _vm.selectedAccount
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip mr-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.tab = 0
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedAccount.orgName) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedApp
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip mr-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.tab = 1
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.selectedApp.appName) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedCampaign
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip mr-2",
                                  attrs: { color: "default", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.tab = 2
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedCampaign.name) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedAdGroup
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "breadcrumb-chip mr-2 active",
                                  attrs: { color: "primary", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.tab = 3
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.selectedAdGroup.name) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "top-heading-right" },
                      [
                        _c(
                          "v-menu",
                          {
                            ref: "menu",
                            attrs: {
                              "close-on-content-click": false,
                              "return-value": _vm.rangePicker,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "auto"
                            },
                            on: {
                              "update:returnValue": function($event) {
                                _vm.rangePicker = $event
                              },
                              "update:return-value": function($event) {
                                _vm.rangePicker = $event
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "v-date-picker-cs",
                                            staticStyle: {
                                              "font-size": "revert"
                                            },
                                            attrs: {
                                              "prepend-icon": "mdi-calendar",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.filterDateRangeText,
                                              callback: function($$v) {
                                                _vm.filterDateRangeText = $$v
                                              },
                                              expression: "filterDateRangeText"
                                            }
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.rangePicker,
                              callback: function($$v) {
                                _vm.rangePicker = $$v
                              },
                              expression: "rangePicker"
                            }
                          },
                          [
                            _c(
                              "v-date-picker",
                              {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  range: ""
                                },
                                model: {
                                  value: _vm.filterDateRange,
                                  callback: function($$v) {
                                    _vm.filterDateRange = $$v
                                  },
                                  expression: "filterDateRange"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "grey" },
                                    on: {
                                      click: function($event) {
                                        _vm.rangePicker = false
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.applyDateRange()
                                      }
                                    }
                                  },
                                  [_vm._v("OK")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "filter-btn-area" },
                          [
                            this.selectedAccount !== 0 ||
                            this.selectedApp !== 0 ||
                            this.selectedCampaign !== 0 ||
                            this.selectedAdGroup !== 0 ||
                            this.selectedTargetingKeyword !== 0 ||
                            this.keyFilter !== ""
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "clear-filter",
                                    attrs: {
                                      depressed: "",
                                      text: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.resetFilters()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-window-close")
                                    ]),
                                    _vm._v("Clear ")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right right-filter-btn",
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  depressed: "",
                                  dense: "",
                                  right: "",
                                  top: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.drawer = !_vm.drawer
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("mdi-filter-variant")]
                                ),
                                _c("span", { staticClass: "black--text" }, [
                                  _vm._v(_vm._s(_vm.$t("buttons.filter")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "custom-tab fixed-tab",
                      attrs: { "background-color": "transparent" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.tabItems, function(item) {
                      return _c(
                        "v-tab",
                        {
                          key: item.tab,
                          on: {
                            change: function($event) {
                              return _vm.onTabChange(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.tab))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "v-main-content", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg",
                        attrs: { elevation: "3" }
                      },
                      [
                        _c(
                          "v-tabs-items",
                          {
                            model: {
                              value: _vm.tab,
                              callback: function($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab"
                            }
                          },
                          [
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { flat: "", elevation: "0" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata:
                                                  _vm.accountsChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.accountsReport &&
                                                    _vm.accountsReport
                                                      .grandTotals &&
                                                    _vm.accountsReport
                                                      .grandTotals.total) ||
                                                  _vm.accountsReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "v-extra-padding",
                                                            attrs: {
                                                              depressed: "",
                                                              dense: "",
                                                              color: "primary",
                                                              large: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$router.push(
                                                                  "integrations"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "popups.add"
                                                                  ) +
                                                                    " " +
                                                                    _vm.$t(
                                                                      "popups.integration"
                                                                    )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("DataTable", {
                                      attrs: {
                                        loading:
                                          _vm.$apollo.queries.accountsReports
                                            .loading,
                                        items:
                                          (_vm.filteredAccountReports &&
                                            _vm.filteredAccountReports.row) ||
                                          [],
                                        headers: _vm.accountHeaders
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item.orgMetadata.orgName",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.accountFilters(
                                                        item.orgMetadata
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.orgMetadata.orgName
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata: _vm.appsChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.appsReport &&
                                                    _vm.appsReport
                                                      .grandTotals &&
                                                    _vm.appsReport.grandTotals
                                                      .total) ||
                                                  _vm.appsReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [_c("v-item")],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("DataTable", {
                                      attrs: {
                                        loading:
                                          _vm.$apollo.queries.appsReports
                                            .loading,
                                        items:
                                          (_vm.filteredAppReports &&
                                            _vm.filteredAppReports.row) ||
                                          [],
                                        headers: _vm.appsHeaders
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item.orgMetadata.orgName",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c("a", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.orgMetadata.orgName
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.metadata.app.appName",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.appFilters(
                                                        item.orgMetadata,
                                                        item.metadata
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.metadata.app.appName
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata:
                                                  _vm.campaignChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.campaignReport &&
                                                    _vm.campaignReport
                                                      .grandTotals &&
                                                    _vm.campaignReport
                                                      .grandTotals.total) ||
                                                  _vm.campaignReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              depressed: "",
                                                              dense: "",
                                                              color: "success",
                                                              large: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$router.push(
                                                                  "CampaginView"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ]),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "buttons.createCampaign"
                                                                )
                                                              ) + " "
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("EditCampaign", {
                                      attrs: {
                                        campaignData: _vm.campaignMetadata
                                      }
                                    }),
                                    _c("DeleteCampaign", {
                                      attrs: {
                                        campaignDetailsForDeletion:
                                          _vm.campaignDeletionMetadata
                                      },
                                      on: {
                                        onDeleteCampaign: function($event) {
                                          return _vm.onDeleteCampaign()
                                        }
                                      }
                                    }),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "mb-4 custom-tab",
                                        attrs: {
                                          "background-color": "transparent"
                                        },
                                        model: {
                                          value: _vm.campaignListingTab,
                                          callback: function($$v) {
                                            _vm.campaignListingTab = $$v
                                          },
                                          expression: "campaignListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.live") +
                                                    " " +
                                                    _vm.$t("subTabs.campaigns")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.liveReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.paused") +
                                                    " " +
                                                    _vm.$t("subTabs.campaigns")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disabledReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.deleted") +
                                                    " " +
                                                    _vm.$t("subTabs.campaigns")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.deletedReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.draft") +
                                                    " " +
                                                    _vm.$t("subTabs.campaigns")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (_vm.getCampaignDrafts &&
                                                            _vm
                                                              .getCampaignDrafts
                                                              .length) ||
                                                            0
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.campaignListingTab,
                                          callback: function($$v) {
                                            _vm.campaignListingTab = $$v
                                          },
                                          expression: "campaignListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .campaignReports.loading,
                                                items:
                                                  (_vm.liveCampaignReports &&
                                                    _vm.liveCampaignReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.campaignHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.campaignMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-0",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openEditCampaignWizard(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.campaignFilters(
                                                                    item.orgMetadata,
                                                                    item
                                                                      .metadata
                                                                      .app,
                                                                    item.campaignMetadata
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item
                                                                      .campaignMetadata
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.metadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.metadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.deleted",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "ma-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "negativeHeaders.deleted"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                icon: "",
                                                                dark: "",
                                                                elevation: "0",
                                                                small: "",
                                                                color: "error"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openDeleteCampaignDialog(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-delete-outline "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              width:
                                                                                "min-content"
                                                                            }
                                                                          },
                                                                          "div",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        item
                                                                          .campaignMetadata
                                                                          .deleted
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "success",
                                                                                  "true-value":
                                                                                    "ENABLED",
                                                                                  "false-value":
                                                                                    "PAUSED",
                                                                                  inset:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  disabled:
                                                                                    ""
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item
                                                                                      .campaignMetadata
                                                                                      .status,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item.campaignMetadata,
                                                                                      "status",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.campaignMetadata.status"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        !item
                                                                          .campaignMetadata
                                                                          .deleted
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "success",
                                                                                  "true-value":
                                                                                    "ENABLED",
                                                                                  "false-value":
                                                                                    "PAUSED",
                                                                                  loading:
                                                                                    _vm.v -
                                                                                    _vm.model,
                                                                                  outlined:
                                                                                    "",
                                                                                  inset:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  change: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.onCampaignStatusChange(
                                                                                      $event,
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item
                                                                                      .campaignMetadata
                                                                                      .status,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item.campaignMetadata,
                                                                                      "status",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.campaignMetadata.status"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          item.campaignMetadata
                                                            .status == "ENABLED"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.disable"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .status == "PAUSED"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.enable"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                items:
                                                  (_vm.disabledCampaignReports &&
                                                    _vm.disabledCampaignReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.campaignHeaders,
                                                loading:
                                                  _vm.$apollo.queries
                                                    .campaignReports.loading
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.campaignMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-0",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openEditCampaignWizard(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.campaignFilters(
                                                                    item.orgMetadata,
                                                                    item
                                                                      .metadata
                                                                      .app,
                                                                    item.campaignMetadata
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item
                                                                      .campaignMetadata
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.metadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.metadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.deleted",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "ma-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "negativeHeaders.deleted"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-2 delete-btn",
                                                              attrs: {
                                                                icon: "",
                                                                dark: "",
                                                                small: "",
                                                                color: "error"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openDeleteCampaignDialog(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    dark: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-delete-outline "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              width:
                                                                                "min-content"
                                                                            }
                                                                          },
                                                                          "div",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        item
                                                                          .campaignMetadata
                                                                          .deleted
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "success",
                                                                                  "true-value":
                                                                                    "ENABLED",
                                                                                  "false-value":
                                                                                    "PAUSED",
                                                                                  inset:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  disabled:
                                                                                    ""
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item
                                                                                      .campaignMetadata
                                                                                      .status,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item.campaignMetadata,
                                                                                      "status",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.campaignMetadata.status"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        !item
                                                                          .campaignMetadata
                                                                          .deleted
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "success",
                                                                                  "true-value":
                                                                                    "ENABLED",
                                                                                  "false-value":
                                                                                    "PAUSED",
                                                                                  loading:
                                                                                    _vm.v -
                                                                                    _vm.model,
                                                                                  inset:
                                                                                    "",
                                                                                  outlined:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  change: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.onCampaignStatusChange(
                                                                                      $event,
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item
                                                                                      .campaignMetadata
                                                                                      .status,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item.campaignMetadata,
                                                                                      "status",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.campaignMetadata.status"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          item.campaignMetadata
                                                            .status == "ENABLED"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.disable"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item.campaignMetadata
                                                            .status == "PAUSED"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.enable"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                items:
                                                  (_vm.deletedCampaignReports &&
                                                    _vm.deletedCampaignReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.campaignHeaders,
                                                loading:
                                                  _vm.$apollo.queries
                                                    .campaignReports.loading
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.campaignMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("p", [
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.campaignFilters(
                                                                  item.orgMetadata,
                                                                  item.metadata
                                                                    .app,
                                                                  item.campaignMetadata
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item
                                                                    .campaignMetadata
                                                                    .name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.metadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.metadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.deleted",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        { staticClass: "ma-2" },
                                                        [_vm._v(" Deleted ")]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass:
                                                          "borderedSwitch",
                                                        attrs: {
                                                          color: "success",
                                                          "true-value":
                                                            "ENABLED",
                                                          "false-value":
                                                            "PAUSED",
                                                          inset: "",
                                                          outlined: "",
                                                          disabled: ""
                                                        },
                                                        model: {
                                                          value: _vm.PAUSED,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.PAUSED = $$v
                                                          },
                                                          expression: "PAUSED"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                headers:
                                                  _vm.campaignDraftHeaders,
                                                items: _vm.getCampaignDrafts,
                                                loading:
                                                  _vm.$apollo.queries
                                                    .getCampaignDrafts.loading
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item.resume",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            depressed: "",
                                                            dense: "",
                                                            color: "success"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openCampaignDraft(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "arrow_forward"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" Resume ")
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata: _vm.adGroupChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.adgroupReport &&
                                                    _vm.adgroupReport
                                                      .grandTotals &&
                                                    _vm.adgroupReport
                                                      .grandTotals.total) ||
                                                  _vm.adGroupReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "success",
                                                                  large: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openAddAdGroup()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              _vm.attrs,
                                                              false
                                                            ),
                                                            _vm.on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "popups.add"
                                                                ) +
                                                                  " " +
                                                                  _vm.$t(
                                                                    "popups.adgroup"
                                                                  )
                                                              ) + " "
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("EditAdGroup", {
                                      attrs: {
                                        adgroupData: _vm.editAdgroupMetadata
                                      }
                                    }),
                                    _c("AddAdGroup", {
                                      attrs: {
                                        openAddAdGroup: _vm.openAddAdgroup
                                      }
                                    }),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "mb-4 custom-tab",
                                        attrs: {
                                          "background-color": "transparent"
                                        },
                                        model: {
                                          value: _vm.adgroupListingTab,
                                          callback: function($$v) {
                                            _vm.adgroupListingTab = $$v
                                          },
                                          expression: "adgroupListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.live") +
                                                    " " +
                                                    _vm.$t("subTabs.adgroups")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.filteredAdgroupReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.paused") +
                                                    " " +
                                                    _vm.$t("subTabs.adgroups")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disabledAdgroupReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.deleted") +
                                                    " " +
                                                    _vm.$t("subTabs.adgroups")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.deletedAdgroupReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.adgroupListingTab,
                                          callback: function($$v) {
                                            _vm.adgroupListingTab = $$v
                                          },
                                          expression: "adgroupListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .adGroupReports.loading,
                                                items:
                                                  (_vm.filteredAdgroupReports &&
                                                    _vm.filteredAdgroupReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.adGroupHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.adgroupMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-0",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openEditAddgroupWizard(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.adgroupFilters(
                                                                    item.orgMetadata,
                                                                    item
                                                                      .metadata
                                                                      .app,
                                                                    item.campaignMetadata,
                                                                    item.adgroupMetadata
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item
                                                                      .adgroupMetadata
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.adgroupMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass:
                                                          "borderedSwitch",
                                                        attrs: {
                                                          color: "success",
                                                          "true-value":
                                                            "ENABLED",
                                                          "false-value":
                                                            "PAUSED",
                                                          loading:
                                                            _vm.v - _vm.model,
                                                          inset: "",
                                                          outlined: ""
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.onAdgroupStatusChange(
                                                              $event,
                                                              item
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            item.adgroupMetadata
                                                              .status,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item.adgroupMetadata,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.adgroupMetadata.status"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .adGroupReports.loading,
                                                items:
                                                  (_vm.disabledAdgroupReports &&
                                                    _vm.disabledAdgroupReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.adGroupHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.adgroupMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-0",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "secondary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openEditAddgroupWizard(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.adgroupFilters(
                                                                    item.orgMetadata,
                                                                    item
                                                                      .metadata
                                                                      .app,
                                                                    item.campaignMetadata,
                                                                    item.adgroupMetadata
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item
                                                                      .adgroupMetadata
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.adgroupMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "ENABLED",
                                                              "false-value":
                                                                "PAUSED",
                                                              loading:
                                                                _vm.v -
                                                                _vm.model,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.onAdgroupStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .adgroupMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.adgroupMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.adgroupMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .adGroupReports.loading,
                                                items:
                                                  (_vm.deletedAdgroupReports &&
                                                    _vm.deletedAdgroupReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.adGroupHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.adgroupMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("p", [
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.adgroupFilters(
                                                                  item.orgMetadata,
                                                                  item.metadata
                                                                    .app,
                                                                  item.campaignMetadata,
                                                                  item.adgroupMetadata
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item
                                                                    .adgroupMetadata
                                                                    .name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.adgroupMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass:
                                                          "borderedSwitch",
                                                        attrs: {
                                                          color: "success",
                                                          "true-value":
                                                            "ENABLED",
                                                          "false-value":
                                                            "PAUSED",
                                                          inset: "",
                                                          outlined: "",
                                                          disabled: ""
                                                        },
                                                        model: {
                                                          value: _vm.PAUSED,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.PAUSED = $$v
                                                          },
                                                          expression: "PAUSED"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata:
                                                  _vm.keywordsChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.keywordReport &&
                                                    _vm.keywordReport
                                                      .grandTotals &&
                                                    _vm.keywordReport
                                                      .grandTotals.total) ||
                                                  _vm.targetingKeywordReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    !_vm.selectedAdGroup
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "success",
                                                                  large: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openSelectAdgroupForKeywords()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              _vm.attrs,
                                                              false
                                                            ),
                                                            _vm.on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "popups.add"
                                                                  ) +
                                                                    " " +
                                                                    _vm.$t(
                                                                      "popups.keywords"
                                                                    )
                                                                )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.selectedAdGroup
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  color:
                                                                    "success",
                                                                  large: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.addKeyToFiltAdgroup()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              _vm.attrs,
                                                              false
                                                            ),
                                                            _vm.on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "popups.add"
                                                                  ) +
                                                                    " " +
                                                                    _vm.$t(
                                                                      "popups.keywords"
                                                                    ) +
                                                                    " to filtered Adgroup"
                                                                )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("SelectAdGroup", {
                                      attrs: {
                                        settings: _vm.selectAdgroupForKeywords
                                      },
                                      on: {
                                        onAdgroupSelection: function($event) {
                                          return _vm.adgroupsSelectedForKeyword(
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _c("AddKeywords", {
                                      attrs: {
                                        adgroupDetails: _vm.adgroupMetadata
                                      },
                                      on: {
                                        onClickChange: function($event) {
                                          return _vm.openSelectAdgroupForKeywords(
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _c("EditBidAmount", {
                                      attrs: {
                                        keywordDetails: _vm.keywordMetaData
                                      }
                                    }),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "mb-4 custom-tab",
                                        attrs: {
                                          "background-color": "transparent"
                                        },
                                        model: {
                                          value: _vm.keywordListingTab,
                                          callback: function($$v) {
                                            _vm.keywordListingTab = $$v
                                          },
                                          expression: "keywordListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.live") +
                                                    " " +
                                                    _vm.$t("subTabs.keywords")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.filteredKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.paused") +
                                                    " " +
                                                    _vm.$t("subTabs.keywords")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disabledKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.deleted") +
                                                    " " +
                                                    _vm.$t("subTabs.keywords")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.deletedKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.keywordListingTab,
                                          callback: function($$v) {
                                            _vm.keywordListingTab = $$v
                                          },
                                          expression: "keywordListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .targetingKeywordReports
                                                    .loading,
                                                items:
                                                  (_vm.filteredKeywordReports &&
                                                    _vm.filteredKeywordReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.keyheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.keywordMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "ACTIVE",
                                                              "false-value":
                                                                "PAUSED",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .keywordMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.keywordMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.keywordMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "ACTIVE",
                                                              "false-value":
                                                                "PAUSED",
                                                              loading:
                                                                item.true -
                                                                _vm.value,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.targetKeywordStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .keywordMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.keywordMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.keywordMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.keywordMetadata.bidAmount.amount",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass:
                                                            "bidAmount-row"
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "no-m-no-p",
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .keywordMetadata
                                                                        .bidAmount
                                                                        .amount
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "no-m-no-p",
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              !item
                                                                .campaignMetadata
                                                                .deleted
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "ml-8 pa-0",
                                                                      attrs: {
                                                                        "max-width":
                                                                          "15",
                                                                        "min-width":
                                                                          "10",
                                                                        height:
                                                                          "20",
                                                                        text:
                                                                          "",
                                                                        depressed:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openEditBidAmount(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-0",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            color:
                                                                              "secondary"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-pencil"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .targetingKeywordReports
                                                    .loading,
                                                items:
                                                  (_vm.disabledKeywordReports &&
                                                    _vm.disabledKeywordReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.keyheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.keywordMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "ACTIVE",
                                                              "false-value":
                                                                "PAUSED",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .keywordMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.keywordMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.keywordMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "ACTIVE",
                                                              "false-value":
                                                                "PAUSED",
                                                              loading:
                                                                item.true -
                                                                _vm.value,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.targetKeywordStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .keywordMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.keywordMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.keywordMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.keywordMetadata.bidAmount.amount",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "no-m-no-p",
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .keywordMetadata
                                                                        .bidAmount
                                                                        .amount
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "no-m-no-p",
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              !item
                                                                .campaignMetadata
                                                                .deleted
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "ml-8 pa-0",
                                                                      attrs: {
                                                                        "max-width":
                                                                          "15",
                                                                        "min-width":
                                                                          "10",
                                                                        height:
                                                                          "20",
                                                                        text:
                                                                          "",
                                                                        depressed:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openEditBidAmount(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-0",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                            color:
                                                                              "secondary"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-pencil"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .targetingKeywordReports
                                                    .loading,
                                                items:
                                                  (_vm.deletedKeywordReports &&
                                                    _vm.deletedKeywordReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.keyheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.keywordMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass:
                                                          "borderedSwitch",
                                                        attrs: {
                                                          color: "success",
                                                          "true-value":
                                                            "ACTIVE",
                                                          "false-value":
                                                            "PAUSED",
                                                          inset: "",
                                                          outlined: "",
                                                          disabled: ""
                                                        },
                                                        model: {
                                                          value: _vm.PAUSED,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.PAUSED = $$v
                                                          },
                                                          expression: "PAUSED"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata:
                                                  _vm.creativeSetChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.creativeSetReport &&
                                                    _vm.creativeSetReport
                                                      .grandTotals &&
                                                    _vm.creativeSetReport
                                                      .grandTotals.total) ||
                                                  _vm.creativeSetReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              depressed: "",
                                                              dense: "",
                                                              large: "",
                                                              color: "success"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openSelectAdgroupForCs()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ]),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "popups.add"
                                                                ) +
                                                                  " " +
                                                                  _vm.$t(
                                                                    "popups.creativeSet"
                                                                  )
                                                              ) + " "
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("EditCreativeSet", {
                                      attrs: {
                                        openUpdateCreativeSetData:
                                          _vm.updateCreativeSetData
                                      }
                                    }),
                                    _c("SelectAdGroup", {
                                      attrs: {
                                        settings: _vm.selectAdgroupForCs
                                      },
                                      on: {
                                        onAdgroupSelection: function($event) {
                                          return _vm.adgroupsSelectedForCs(
                                            $event
                                          )
                                        }
                                      }
                                    }),
                                    _c("AddCreativeSet", {
                                      attrs: {
                                        appDetails: _vm.creativeSetMetadata
                                      },
                                      on: {
                                        onSaveCreativeSet: function($event) {
                                          return _vm.addCreativeSet($event)
                                        }
                                      }
                                    }),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "mb-4 custom-tab",
                                        attrs: {
                                          "background-color": "transparent"
                                        },
                                        model: {
                                          value: _vm.creativeListingTab,
                                          callback: function($$v) {
                                            _vm.creativeListingTab = $$v
                                          },
                                          expression: "creativeListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.live") +
                                                    " " +
                                                    _vm.$t(
                                                      "subTabs.creativeSet"
                                                    )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.filteredCreativeSetReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.paused") +
                                                    " " +
                                                    _vm.$t(
                                                      "subTabs.creativeSet"
                                                    )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disabledCreativeSetReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.deleted") +
                                                    " " +
                                                    _vm.$t(
                                                      "subTabs.creativeSet"
                                                    )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.deletedCreativeSetReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.creativeListingTab,
                                          callback: function($$v) {
                                            _vm.creativeListingTab = $$v
                                          },
                                          expression: "creativeListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .creativeSetReports.loading,
                                                items:
                                                  (_vm.filteredCreativeSetReports &&
                                                    _vm
                                                      .filteredCreativeSetReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.creativeheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-0",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openUpdateCreativeSet(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .creativeSetMetadata
                                                                  .name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              loading:
                                                                item.true -
                                                                _vm.value,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.onCreativeSetStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .creativeSetReports.loading,
                                                items:
                                                  (_vm.disabledCreativeSetReports &&
                                                    _vm
                                                      .disabledCreativeSetReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.creativeheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-0",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openUpdateCreativeSet(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .creativeSetMetadata
                                                                  .name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              loading:
                                                                item.true -
                                                                _vm.value,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.onCreativeSetStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .creativeSetReports.loading,
                                                items:
                                                  (_vm.deletedCreativeSetReports &&
                                                    _vm
                                                      .deletedCreativeSetReports
                                                      .row) ||
                                                  [],
                                                headers: _vm.creativeheaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.name",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "p",
                                                        [
                                                          !item.campaignMetadata
                                                            .deleted
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-0",
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "secondary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openUpdateCreativeSet(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item
                                                                  .creativeSetMetadata
                                                                  .name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.campaignMetadata.countriesOrRegions",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return _vm._l(
                                                      item.campaignMetadata
                                                        .countriesOrRegions,
                                                      function(country) {
                                                        return _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(country) +
                                                              " "
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  }
                                                },
                                                {
                                                  key:
                                                    "item.creativeSetMetadata.status",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              inset: "",
                                                              outlined: "",
                                                              disabled: ""
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !item.campaignMetadata
                                                        .deleted
                                                        ? _c("v-switch", {
                                                            staticClass:
                                                              "borderedSwitch",
                                                            attrs: {
                                                              color: "success",
                                                              "true-value":
                                                                "VALID",
                                                              "false-value":
                                                                "INVALID",
                                                              loading:
                                                                item.true -
                                                                _vm.value,
                                                              inset: "",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.onCreativeSetStatusChange(
                                                                  $event,
                                                                  item
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item
                                                                  .creativeSetMetadata
                                                                  .status,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item.creativeSetMetadata,
                                                                  "status",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.creativeSetMetadata.status"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("Chart", {
                                              attrs: {
                                                chartdata:
                                                  _vm.searchTermsChartData,
                                                options: _vm.chartOptions
                                              },
                                              on: {
                                                granularityChanged:
                                                  _vm.handleGranularityChange
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "6" } },
                                          [
                                            _c("TotalSummary", {
                                              attrs: {
                                                values:
                                                  (_vm.searchTermsReport &&
                                                    _vm.searchTermsReport
                                                      .grandTotals &&
                                                    _vm.searchTermsReport
                                                      .grandTotals.total) ||
                                                  _vm.searchTermsReports
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: { cols: "12", md: "6" }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("DataTable", {
                                      attrs: {
                                        loading:
                                          _vm.$apollo.queries.searchTermsReports
                                            .loading,
                                        items:
                                          (_vm.filteredSearchTermsReports &&
                                            _vm.filteredSearchTermsReports
                                              .row) ||
                                          [],
                                        headers: _vm.searchTermsHeader
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-tab-item",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", {
                                          attrs: { cols: "12", md: "6" }
                                        }),
                                        _c("v-col", {
                                          attrs: { cols: "12", md: "6" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "actions-col py-5" },
                                      [
                                        _c(
                                          "v-item-group",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  depressed: "",
                                                                  dense: "",
                                                                  large: "",
                                                                  color:
                                                                    "success"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openAddNegativeKeyword()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              _vm.attrs,
                                                              false
                                                            ),
                                                            _vm.on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("mdi-plus")
                                                            ]),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "popups.add"
                                                                ) +
                                                                  " " +
                                                                  _vm.$t(
                                                                    "popups.negKey"
                                                                  )
                                                              ) + " "
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("AddNegativeKeywords", {
                                      attrs: {
                                        openNegativeKeyword:
                                          _vm.openAddNegativeKeywords
                                      }
                                    }),
                                    _c("DeleteNegKey", {
                                      attrs: {
                                        negKeyDeletionData:
                                          _vm.negKeyDeletionData
                                      },
                                      on: {
                                        onDeletion: function($event) {
                                          return _vm.onDeletingNegKey()
                                        }
                                      }
                                    }),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "mb-4 custom-tab",
                                        attrs: {
                                          "background-color": "transparent"
                                        },
                                        model: {
                                          value: _vm.negKeyListingTab,
                                          callback: function($$v) {
                                            _vm.negKeyListingTab = $$v
                                          },
                                          expression: "negKeyListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.live") +
                                                    " " +
                                                    _vm.$t("subTabs.negKey")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.filteredNegKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.paused") +
                                                    " " +
                                                    _vm.$t("subTabs.negKey")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disabledNegKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("subTabs.deleted") +
                                                    " " +
                                                    _vm.$t("subTabs.negKey")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mx-3",
                                                attrs: {
                                                  color: "primary",
                                                  size: "26"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.deletedNegKeywordReportsCount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tabs-items",
                                      {
                                        staticClass: "show-full-content",
                                        model: {
                                          value: _vm.negKeyListingTab,
                                          callback: function($$v) {
                                            _vm.negKeyListingTab = $$v
                                          },
                                          expression: "negKeyListingTab"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .negativeKeywordReports
                                                    .loading,
                                                items:
                                                  _vm.filteredNegKeywordReports ||
                                                  [],
                                                headers: _vm.negativeHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item.Action",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mx-0",
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            small: "",
                                                            color: "error"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openDeleteNegKeyDialog(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-delete-outline "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .negativeKeywordReports
                                                    .loading,
                                                items:
                                                  _vm.disabledNegKeywordReports ||
                                                  [],
                                                headers: _vm.negativeHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item.Action",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mx-0",
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            small: "",
                                                            color: "error"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openDeleteNegKeyDialog(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-delete-outline "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c("DataTable", {
                                              attrs: {
                                                loading:
                                                  _vm.$apollo.queries
                                                    .negativeKeywordReports
                                                    .loading,
                                                items:
                                                  _vm.deletedNegKeywordReports ||
                                                  [],
                                                headers: _vm.negativeHeaders
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item.Action",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        { staticClass: "ma-0" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "negativeHeaders.deleted"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }