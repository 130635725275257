var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px" },
      model: {
        value: _vm.adGroup,
        callback: function($$v) {
          _vm.adGroup = $$v
        },
        expression: "adGroup"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3  v-modal-header"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addAdgroup.addAd")))
              ]),
              _c(
                "v-icon",
                { staticClass: "black--text", on: { click: _vm.close } },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-4" },
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  !_vm.selectedCampaign
                    ? _c(
                        "p",
                        { staticClass: "secondary--text success--text" },
                        [_vm._v(" " + _vm._s(_vm.$t("addAdgroup.para")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "6", md: "6", sm: "12" } },
                        [
                          _c("label", { staticClass: "mt-2 black--text" }, [
                            _vm._v(_vm._s(_vm.$t("addAdgroup.camGroup")))
                          ]),
                          _c("v-select", {
                            staticClass: "rounded-lg",
                            attrs: {
                              placeholder: "Campaign Group",
                              green: "",
                              "item-text": "orgName",
                              "item-value": "orgId",
                              items: _vm.getCampaignGroups,
                              rules: _vm.accountRules,
                              required: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.selectedAccount,
                              callback: function($$v) {
                                _vm.selectedAccount = $$v
                              },
                              expression: "selectedAccount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "6", md: "6", sm: "12" } },
                        [
                          _c("label", { staticClass: "mt-2 black--text" }, [
                            _vm._v(_vm._s(_vm.$t("addAdgroup.campaign")))
                          ]),
                          _c("v-select", {
                            staticClass: "rounded-lg",
                            attrs: {
                              green: "",
                              placeholder: "Campaign",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.getCampaignsByOrg,
                              rules: _vm.campaignRules,
                              required: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.selectedCampaign,
                              callback: function($$v) {
                                _vm.selectedCampaign = $$v
                              },
                              expression: "selectedCampaign"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", {
                        staticClass: "pa-0 text-right",
                        attrs: { cols: "12", sm: "4" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      staticClass: "adgroupform",
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("h5", { staticClass: "black--text" }, [
                        _vm._v(" Campaign: "),
                        _c("span", { staticClass: "font-weight-light" }, [
                          _vm._v(_vm._s(_vm.selectedCampaign))
                        ])
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold pb-0",
                              attrs: { cols: "12", sm: "6" }
                            },
                            [_vm._v(_vm._s(_vm.$t("addAdgroup.adgroupSett")))]
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "my-4" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { lg: "12", md: "12", sm: "12" }
                            },
                            [
                              _c("p", { staticClass: "ma-0" }, [
                                _vm._v(_vm._s(_vm.$t("addAdgroup.para1")))
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "6", md: "6", sm: "12" } },
                            [
                              _c("label", { staticClass: "mb-4 black--text" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("addAdgroup.adgroupName")) + " "
                                ),
                                _c("sup", { staticClass: "red--text" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Ad Group Name",
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  rules: _vm.nameRules
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "6", md: "6", sm: "12" } },
                            [
                              _c("label", { staticClass: "mb-4 black--text" }, [
                                _vm._v(_vm._s(_vm.$t("addAdgroup.devices")))
                              ]),
                              _c("v-select", {
                                attrs: {
                                  placeholder: "Select devices",
                                  items: _vm.Devices,
                                  required: "",
                                  outlined: "",
                                  multiple: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.selectDevices,
                                  callback: function($$v) {
                                    _vm.selectDevices = $$v
                                  },
                                  expression: "selectDevices"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { lg: "12", md: "12", sm: "12" }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "black--text mb-3 d-flex" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("addAdgroup.adScheduling"))
                                  ),
                                  _c("sup", { staticClass: "red--text" }, [
                                    _vm._v("*")
                                  ])
                                ]
                              ),
                              _c(
                                "v-expansion-panels",
                                {
                                  model: {
                                    value: _vm.panel,
                                    callback: function($$v) {
                                      _vm.panel = $$v
                                    },
                                    expression: "panel"
                                  }
                                },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("addAdgroup.adScheduling")
                                          )
                                        )
                                      ]),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "field mt-5"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "black--text mb-3 d-flex"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addAdgroup.startTime"
                                                              )
                                                            )
                                                          ),
                                                          _c(
                                                            "sup",
                                                            {
                                                              staticClass:
                                                                "red--text"
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-datetime-picker", {
                                                        attrs: {
                                                          datePickerProps:
                                                            _vm.datePickerProps
                                                        },
                                                        model: {
                                                          value: _vm.startTime,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.startTime = $$v
                                                          },
                                                          expression:
                                                            "startTime"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "field" },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: "End Time (Optional)",
                                                  color: "primary",
                                                  value: "End Time",
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value: _vm.endTimeCheck,
                                                  callback: function($$v) {
                                                    _vm.endTimeCheck = $$v
                                                  },
                                                  expression: "endTimeCheck"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "field mt-10" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "black--text mb-3 d-flex"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addAdgroup.endTime"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("v-datetime-picker", {
                                                attrs: {
                                                  outlined: "",
                                                  datePickerProps:
                                                    _vm.datePickerProps,
                                                  disabled: !_vm.endTimeCheck
                                                },
                                                model: {
                                                  value: _vm.endTime,
                                                  callback: function($$v) {
                                                    _vm.endTime = $$v
                                                  },
                                                  expression: "endTime"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "field mt-10" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "black--text mb-3 d-flex"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addAdgroup.dayparting"
                                                      )
                                                    ) + " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-help-circle")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-datetime-picker", {
                                                attrs: { outlined: "" },
                                                model: {
                                                  value: _vm.endTime,
                                                  callback: function($$v) {
                                                    _vm.endTime = $$v
                                                  },
                                                  expression: "endTime"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-5", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-3 d-flex" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("addAdgroup.defaultMax")
                                        ) + " "
                                      ),
                                      _c("sup", { staticClass: "red--text" }, [
                                        _vm._v("*")
                                      ]),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "400"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { small: "" }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-help-circle")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("addAdgroup.cptTool")
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      "prepend-inner-icon": "mdi-currency-usd",
                                      rules: _vm.amountRules
                                    },
                                    model: {
                                      value: _vm.defaultBidAmount,
                                      callback: function($$v) {
                                        _vm.defaultBidAmount = $$v
                                      },
                                      expression: "defaultBidAmount"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "field" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "black--text mb-3 d-flex" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("addAdgroup.cpaGoal"))
                                      ),
                                      _c("sup", { staticClass: "red--text" }, [
                                        _vm._v("*")
                                      ]),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "400"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: { small: "" }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-help-circle")]
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("addAdgroup.cpaTool")
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      "prepend-inner-icon": "mdi-currency-usd",
                                      rules: _vm.cpaRules
                                    },
                                    model: {
                                      value: _vm.cpaGoal,
                                      callback: function($$v) {
                                        _vm.cpaGoal = $$v
                                      },
                                      expression: "cpaGoal"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("h5", { staticClass: "font-weight-bold pb-0 mb-0" }, [
                        _vm._v("Search Match")
                      ]),
                      _c("v-divider", { staticClass: "my-4 mt-0" }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "12", md: "12", sm: "12" } },
                            [
                              _c("p", { staticClass: "ma-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("addAdgroup.searchTool")) +
                                    " "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { lg: "6", md: "6", sm: "12" }
                            },
                            [
                              _c("v-label", { staticClass: "mb-4" }, [
                                _vm._v("Search Match")
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 d-flex align-center",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c("p", { staticClass: "my-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("addAdgroup.searchPara")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-switch", {
                                    attrs: { inset: "" },
                                    model: {
                                      value: _vm.autoOpt,
                                      callback: function($$v) {
                                        _vm.autoOpt = $$v
                                      },
                                      expression: "autoOpt"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "h5",
                        { staticClass: "font-weight-bold pb-0 mb-0 mt-4" },
                        [_vm._v("Keywords")]
                      ),
                      _c("v-divider", { staticClass: "my-4 mt-0" }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                            _c("p", { staticClass: "ma-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("addAdgroup.keyPara")) + " "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { lg: "12", md: "12", sm: "12" } },
                            [
                              !_vm.defaultBidAmount
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "mb-0 pa-4 d-flex align-center justify-center",
                                      attrs: { outlined: "" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v("mdi-alert")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("addAdgroup.inputCpt")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.defaultBidAmount
                                ? _c(
                                    "div",
                                    { staticClass: "keywordwithprice" },
                                    [
                                      _c("v-label", { staticClass: "mb-4" }, [
                                        _vm._v("Ad Group Keywords")
                                      ]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "upload-file text-center mb-0"
                                                },
                                                [
                                                  _c("v-file-input", {
                                                    attrs: {
                                                      "hide-input": "",
                                                      solo: "",
                                                      accept:
                                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
                                                    },
                                                    on: {
                                                      change: _vm.tKeysChange
                                                    }
                                                  }),
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "addAdgroup.addKeyPara"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("p", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "green--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addAdgroup.upload"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                lg: "6",
                                                md: "6",
                                                sm: "12"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "upload-file text-center mb-0"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { depressed: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addTargetKeywords()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addAdgroup.addKeyManually"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { lg: "12", md: "12", sm: "12" }
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "black--text mb-0 d-flex" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("addAdgroup.negKey")) + " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "6", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: { elevation: "0" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ad-group mt-0" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "upload-file text-center mt-0"
                                                        },
                                                        "div",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-file-input", {
                                                        staticClass:
                                                          "mt-0 pt-0",
                                                        attrs: {
                                                          "hide-input": "",
                                                          solo: "",
                                                          accept:
                                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.nKeysChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.negativeKeywordsFile,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.negativeKeywordsFile = $$v
                                                          },
                                                          expression:
                                                            "negativeKeywordsFile"
                                                        }
                                                      }),
                                                      _c("p", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "green--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "addAdgroup.upload"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("addAdgroup.supp"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "6", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: { elevation: "0" }
                                },
                                [
                                  _c("div", { staticClass: "ad-group mt-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-file text-center mt-0"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addNegativeKeywords()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "addAdgroup.addKeyManually"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("createCampaign.audience")))
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("createCampaign.audPara")) +
                                  " "
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c(
                                "v-expansion-panels",
                                {
                                  attrs: { multiple: "" },
                                  model: {
                                    value: _vm.panel,
                                    callback: function($$v) {
                                      _vm.panel = $$v
                                    },
                                    expression: "panel"
                                  }
                                },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createCampaign.custType")
                                          )
                                        )
                                      ]),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.customerTypes,
                                              label: "Customer Types",
                                              outlined: "",
                                              dense: ""
                                            },
                                            model: {
                                              value: _vm.selectedCustomerType,
                                              callback: function($$v) {
                                                _vm.selectedCustomerType = $$v
                                              },
                                              expression: "selectedCustomerType"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          _vm._s(_vm.$t("addAdgroup.demAge"))
                                        )
                                      ]),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("addAdgroup.demPara")
                                              )
                                            )
                                          ]),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "black--text mb-3 d-flex"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("addAdgroup.ageRange")
                                                )
                                              )
                                            ]
                                          ),
                                          _c("v-switch", {
                                            attrs: { inset: "" },
                                            model: {
                                              value: _vm.ageRange,
                                              callback: function($$v) {
                                                _vm.ageRange = $$v
                                              },
                                              expression: "ageRange"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "range" },
                                            [
                                              _c("v-range-slider", {
                                                attrs: {
                                                  "thumb-label": true,
                                                  disabled: !_vm.ageRange,
                                                  step: "1",
                                                  min: "18",
                                                  max: "65",
                                                  "background-color": "light",
                                                  "track-color": "grey",
                                                  "track-fill-color": "success",
                                                  "thumb-color": "success"
                                                },
                                                model: {
                                                  value: _vm.ageRangeValue,
                                                  callback: function($$v) {
                                                    _vm.ageRangeValue = $$v
                                                  },
                                                  expression: "ageRangeValue"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "field" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "black--text mb-3 d-flex"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.gender"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.gender,
                                                  label: "Gender",
                                                  required: "",
                                                  outlined: "",
                                                  dense: ""
                                                },
                                                model: {
                                                  value: _vm.selectedGender,
                                                  callback: function($$v) {
                                                    _vm.selectedGender = $$v
                                                  },
                                                  expression: "selectedGender"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("createCampaign.location")
                                          )
                                        )
                                      ]),
                                      _c("v-expansion-panel-content", [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.locations,
                                                "item-text": "displayName",
                                                "item-value": "id",
                                                label: "Locations",
                                                required: "",
                                                outlined: "",
                                                dense: "",
                                                multiple: "",
                                                "hide-no-data": "",
                                                "hide-details": "",
                                                loading: _vm.loading,
                                                "search-input": _vm.searchLoc,
                                                "cache-items": ""
                                              },
                                              on: {
                                                "update:searchInput": function(
                                                  $event
                                                ) {
                                                  _vm.searchLoc = $event
                                                },
                                                "update:search-input": function(
                                                  $event
                                                ) {
                                                  _vm.searchLoc = $event
                                                }
                                              },
                                              model: {
                                                value: _vm.selectedLocation,
                                                callback: function($$v) {
                                                  _vm.selectedLocation = $$v
                                                },
                                                expression: "selectedLocation"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("AddKeys", {
                    attrs: { addKeysData: _vm.addKeywordsData },
                    on: {
                      addKeysCancelClicked: _vm.addTargetKeysCancelClicked,
                      addKeysSaveClicked: function($event) {
                        return _vm.addTargetKeysSaveClicked($event)
                      }
                    }
                  }),
                  _c("AddnKeys", {
                    attrs: { AddnKeysData: _vm.addnKeywordsData },
                    on: {
                      emitAddnKeysCancelClicked: _vm.addnKeysCancelClicked,
                      emitAddnKeysOkClicked: function($event) {
                        return _vm.addnKeysSaveClicked($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-0 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    disabled: _vm.saving,
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("createCampaign.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "success",
                    depressed: "",
                    loading: _vm.saving,
                    disabled:
                      !_vm.valid ||
                      _vm.startTime == null ||
                      _vm.selectedAccount == null ||
                      _vm.selectedCampaign == null ||
                      _vm.saving
                  },
                  on: {
                    click: function($event) {
                      return _vm.adGroupObject()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("addAdgroup.apply")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }