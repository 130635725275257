import { render, staticRenderFns } from "./AddNegativeKeywords.vue?vue&type=template&id=da668b00&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&"
import script from "./AddNegativeKeywords.vue?vue&type=script&lang=ts&"
export * from "./AddNegativeKeywords.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VDialog,VDivider,VForm,VIcon,VImg,VRadio,VRadioGroup,VRow,VSelect,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-backend/sam-backend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da668b00')) {
      api.createRecord('da668b00', component.options)
    } else {
      api.reload('da668b00', component.options)
    }
    module.hot.accept("./AddNegativeKeywords.vue?vue&type=template&id=da668b00&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D&", function () {
      api.rerender('da668b00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/negativekeywords/AddNegativeKeywords.vue"
export default component.exports