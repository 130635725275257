





































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { DEFAULT_GRANULARITY } from '@shared/config';
import LineChart from '../chartjs/LineChart.vue';

export default Vue.extend({
  name: 'Chart',
  components: {
    LineChart,
  },
  props: {
    chartdata: {
      type: Object,
      default() { return { labels: [], datasets: [] } }
    },
    options: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      granularity: DEFAULT_GRANULARITY,
      selectedDatasets: [],
      datasetsList: [],
      linechartData: { labels: [], datasets: [] },
      selectedSetNamesToPersist: []
    };
  },
  computed: {
    granularityList: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('dropdowns.daily'),
            value: 'DAILY'
          },
          {
            text: this.$t('dropdowns.weekly'),
            value: 'WEEKLY'
          },
          {
            text: this.$t('dropdowns.monthly'),
            value: 'MONTHLY'
          },
        ];
      }
    },
  },
  watch: {
    chartdata: function(data) {
      this.datasetsList = data.datasets;
      // show persisted selections or first 3
      this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.slice(0, 3);
      this.linechartData = { labels: data.labels, datasets: this.selectedDatasets };
    }
  },
  methods: {
    removeChipByLabel(label: string) {
      this.selectedDatasets = this.selectedDatasets.filter((item: { label: string }) => item.label !== label);
      this.changeSelectedDatasets();
    },

    changeSelectedDatasets(): void {
      this.selectedSetNamesToPersist = this.selectedDatasets.map((set) => set.label);
      this.linechartData = { labels: this.chartdata.labels, datasets: this.selectedDatasets };
    },
    emitGranularityChange(): void {
      this.$emit('granularityChanged', this.granularity);
    },
  }
});
